import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Translate
import { useTranslation } from 'react-i18next';

// Import css files
import $ from 'jquery';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import RBCarousel from 'react-bootstrap-carousel';
import { Row, Col, Button, ButtonGroup } from "./../navbar/bootstrap-component.jsx";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";

import BannerBgImg1 from "../../img/bg/banner-all-product.jpg";
// import BannerBgImg2 from "../../img/bg/reef-corals-blue-clear-water.jpg";
// import BannerBgImg3 from "../../img//japanese/j-02.jpg";


// class Banner_ extends Component { 
// }

const styles = {
	width: '100%',
	height: '95vh',
	margin: '0',
	padding: '0',

	'@media(maxWidth: 767px)': {
		width: '900%',
		height: '60vh',
	}
};

const Banner = () => {
	const { t, i18n } = useTranslation(["translation"]);

	return (
		<div>
			{/* <Col span={13} style={{
                padding: '0',
                margin: '0'
            }}>
                <RBCarousel className="carousel-fade"
                    version={4}
                    autoplay={true}
                    pauseOnVisibility={true} 
                    slideshowSpeed={3000}
                >
                    <div style={{ ...styles, backgroundImage: `url(${BannerBgImg2})`, width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                        <span className="carousel-center">
                            <h2>{t('translation:quote.q02')}</h2>
                        </span>
                    </div>
                    <div style={{ ...styles, backgroundImage: `url(${BannerBgImg1})`, width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover",backgroundPosition: "bottom" }}>
                        <div className="carousel-center">
                            <h2>{t('translation:quote.q01')}</h2>
                        </div>
                    </div> 
                </RBCarousel>
            </Col> */}
			<div style={{ ...styles, backgroundImage: `url(${BannerBgImg1})`, width: "100%", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
				<div className="banner-content">
					<img src={require("../../img/logo/logo-gpc.png")} />
					
					<div className="display-pc">
						<h4>{t('gpcb')}</h4>
						<h5>{t('quote.q02')}</h5>
					</div>

					<div className="banner-content-text text-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 591.4 334.3">
							<path d="M108.61,86.14c0,0.01,0.01,0.04,0.02,0.09l-0.06,0.17h0.02v0.04c0,0.03-0.01,0.05-0.02,0.06
								c-0.66,0.5-2.27,0.97-4.83,1.39c-2.56,0.42-4.33,0.63-5.28,0.63c-0.96,0-1.49-0.01-1.61-0.02l-1.07,0.06
								c-1.12,0.07-1.77,0.11-1.94,0.11c-5.89,0-10.38-1.48-13.47-4.44s-4.62-7.17-4.62-12.62s1.66-9.59,4.98-12.41s7.81-4.23,13.48-4.23
								c1.04,0,2.32,0.1,3.84,0.29c1.52,0.19,2.86,0.48,4.01,0.86c1.4,0.57,2.61,1.41,3.61,2.49c1.01,1.08,1.66,2.29,1.97,3.61
								c0.06,0.37,0.12,0.93,0.2,1.72c0.08,0.78,0.14,1.35,0.2,1.72c-0.09,0.21-0.39,0.32-0.91,0.32l-10.69-0.19
								c-0.06-0.03-0.13-0.22-0.21-0.57v0.06c-0.25-1.14-0.75-1.83-1.49-2.08c-0.34-0.13-0.78-0.19-1.33-0.19s-1.06,0.09-1.54,0.27
								s-0.89,0.44-1.21,0.75c-0.32,0.32-0.61,0.7-0.86,1.13s-0.45,0.89-0.57,1.38s-0.24,1-0.34,1.52s-0.16,1.03-0.18,1.54
								c-0.02,0.5-0.04,0.99-0.06,1.45s-0.02,0.85-0.01,1.15c0.01,0.3,0.02,0.5,0.02,0.6v1.45c0,0.91,0.04,1.54,0.13,1.9
								s0.18,0.75,0.29,1.18s0.24,0.75,0.4,0.98c0.55,0.77,1.14,1.31,1.78,1.61s1.63,0.45,2.99,0.45c1.35,0,2.64-0.2,3.87-0.61l0.11-3.33
								l-1.52-0.13l-0.21,0.11h-2.19l-0.25,0.02l-0.52-0.55c-0.03-0.24-0.04-0.62-0.04-1.14v-4.71c0-0.24,0.17-0.47,0.5-0.72h1.41
								c1.22,0,2.59,0.01,4.1,0.04h0.21c1.37,0.03,2.42,0.04,3.14,0.04h1.26l0.48-0.02c2.5,0,3.92,0.16,4.28,0.48l-0.25,15.98v0.29h-0.02
								V86.14z"mask="url('#writing')" />
							<path d="M110.78,71.53l0.04-0.46c0.17-1.75,0.4-3.31,0.68-4.66s0.74-2.62,1.37-3.8c1.5-2.59,3.59-4.56,6.29-5.91
								c2.69-1.34,5.58-2.01,8.69-2.01s5.94,0.63,8.47,1.88c1.52,0.75,2.7,1.68,3.56,2.82c1.65,2.05,2.72,3.94,3.24,5.66
								c0.52,1.72,0.78,3.72,0.78,5.98c0,5.02-1.04,8.85-3.12,11.5l-0.97,1.29c-1.31,1.52-3.15,2.69-5.54,3.51s-4.75,1.24-7.09,1.27
								c-4.02,0-7.31-0.84-9.87-2.53C112.96,83.24,110.78,78.39,110.78,71.53z M122.89,71.37c0,0.01-0.01,0.05-0.02,0.11v0.06
								c-0.06,0.59-0.09,1.25-0.09,1.98s0.06,1.5,0.18,2.31s0.32,1.63,0.59,2.44c0.27,0.81,0.7,1.47,1.27,1.98
								c0.62,0.53,1.31,0.8,2.08,0.8c0.76,0,1.41-0.11,1.94-0.32c0.53-0.22,0.97-0.5,1.33-0.83c0.36-0.34,0.67-0.79,0.93-1.37
								c0.68-1.53,1.01-3.9,1.01-7.11c0-3.27-0.37-5.56-1.13-6.88c-0.75-1.31-1.88-1.97-3.4-1.97c-0.75,0-1.42,0.18-1.99,0.55
								s-1,0.75-1.29,1.14c-0.29,0.4-0.58,1.23-0.88,2.51c-0.3,1.28-0.45,2.46-0.45,3.54L122.89,71.37z"mask="url('#writing')" />
							<path d="M171.41,79.28c-0.1,0.4-0.15,0.81-0.15,1.26v0.48c0,0.75,0.05,1.28,0.15,1.6c0,2.12-0.01,3.75-0.02,4.91
								c-0.09,0.37-7.01,0.55-20.78,0.55c-0.63,0-1.67-0.01-3.14-0.04c-0.22-0.42-0.34-1.11-0.34-2.08l0.04-1.37v-4.78l0.02-4v-3.96
								l0.02-6.38c0-1.18-0.01-2.28-0.04-3.33v-0.23l-0.06-3.03c0-1.78,0.15-2.94,0.45-3.47c0.28,0.01,0.67,0.02,1.16,0.02h1.01
								l3.54-0.04c4.03,0,6.24,0.25,6.65,0.75l-0.17,7.34l-0.06,6.17l-0.02,0.25l-0.09,8.76l0.84,0.48c2.54-0.15,5-0.23,7.38-0.23
								C170.18,78.91,171.39,79.04,171.41,79.28z"mask="url('#writing')" />
							<path d="M182.13,55.53l3.94-0.06c6.86,0,11.68,1.14,14.46,3.41c0.59,0.44,1.24,1.16,1.94,2.19
								c2.03,2.96,3.05,6.23,3.05,9.81c0,6.39-2.2,11.11-6.59,14.17c-2.62,1.84-5.64,2.77-9.05,2.8l-0.09,0.09h-0.52l-5.6-0.06
								c-2.54,0-5.72,0.06-9.53,0.19c-0.27-0.5-0.4-1.1-0.4-1.79l0.02-0.75v-3.28l0.04-4.67v-7.7c-0.03-1.77-0.04-3.09-0.04-3.96v-3.45
								l0.02-0.48v-6.04c0-0.11,0.76-0.21,2.29-0.29s2.75-0.11,3.66-0.11h2.4V55.53z M191.05,62.82c-0.8-0.57-1.68-0.9-2.65-0.97
								c-0.34-0.01-0.8-0.03-1.38-0.04c-0.58-0.01-1.01-0.03-1.27-0.04c-0.25,0.38-0.42,0.66-0.5,0.84l-0.38,0.78v1.18
								c0,2.43,0.06,5.72,0.17,9.87l0.13,6.09c0.09,0.44,0.37,0.7,0.84,0.78c1.44-0.03,2.56-0.13,3.35-0.29
								c2.77-0.98,4.15-4.34,4.15-10.08C193.49,66.67,192.68,63.97,191.05,62.82z"mask="url('#writing')" />
							<path d="M208.4,61.3l-0.11-5.54c0.01,0,0.06,0.02,0.15,0.06c10.66-0.18,18.7-0.28,24.1-0.29
								c0.06,0.03,0.1,0.07,0.13,0.13v5.94c0,0.57-0.01,1.05-0.04,1.41c-0.07,0.22-0.48,0.34-1.24,0.34h-0.15
								c-3.4,0-6.93-0.06-10.61-0.17c0,0.13-0.01,0.35-0.05,0.68c-0.04,0.32-0.06,0.55-0.07,0.7l-0.11,0.8
								c-0.07,0.53-0.12,1.46-0.15,2.78l6.52-0.15c1.12-0.03,1.94-0.04,2.44-0.04h0.34c0.86,0,1.42,0.02,1.68,0.07
								c0.27,0.05,0.4,0.19,0.4,0.43l-0.02,0.17v0.5l0.11,5.41l-0.02,0.02v-0.02c-4.27,0.21-7.67,0.32-10.23,0.32h-0.99v5.09l11.43-0.36
								c0.63,0,1.03,0.09,1.2,0.27c-0.01,0.37-0.02,0.88-0.02,1.56l0.15,5.73c0,0.35-0.16,0.55-0.46,0.61l-6.99-0.06
								c-1.37,0-4.11,0.05-8.21,0.15l-3.45,0.09c-2.44,0.09-4.25,0.13-5.43,0.13l-0.06-0.04h0.02c-0.14-0.14-0.27-0.27-0.4-0.4v-0.74
								l0.13-22.5L208.4,61.3z"mask="url('#writing')" />
							<path d="M267.74,72.62l-0.02,1.41v7.79l0.04,3.71v1.05l0.02,0.99c-0.09,0.11-0.43,0.17-1.03,0.17h-0.55l-6.61,0.09
								c-2.81,0-4.9-0.01-6.27-0.02c-0.21-0.22-0.98-2.26-2.29-6.13L247,71l-0.75,0.11l-0.02,2.15v5.71l0.09,5.71v2.36
								c0,0.63-0.14,0.96-0.42,0.99c-1.39-0.04-2.72-0.06-3.98-0.06h-0.65l-3.18,0.09h-0.42l-1.52,0.04l-0.06-0.04
								c-0.46-0.44-0.7-0.75-0.7-0.95v-0.38l0.02-0.42l0.11-8.21c0-9.01,0.09-15.82,0.25-20.44l0.04-1.75c0-0.13,4.84-0.24,14.51-0.34
								c0.09,0.06,0.21,0.37,0.38,0.93v-0.06l0.13,0.42c1.04,2.97,2.3,6.39,3.79,10.24c1.49,3.85,2.25,5.82,2.27,5.91l0.72-0.04
								l0.09-17.16c0-0.11,1.03-0.17,3.08-0.17l5.28,0.11h1.56C267.69,60.37,267.74,66,267.74,72.62z"mask="url('#writing')" />
							<path d="M287.38,73.45l0.04-8.65v-8.78c0.03-0.01,0.16-0.02,0.4-0.02h0.78c0.29,0,0.52-0.01,0.68-0.04l2.61-0.11
								c0.68-0.03,1.19-0.04,1.56-0.04h3.35l6.19-0.15c0.84,0,1.77,0.04,2.78,0.11h0.15c0.47-0.03,0.78-0.04,0.91-0.04
								c2.81,0,5.21,0.89,7.2,2.67c0.97,0.86,1.73,1.95,2.29,3.31s0.84,2.74,0.84,4.18c0,1.44-0.15,2.67-0.45,3.67
								c-0.29,1.01-0.68,1.87-1.14,2.57c-1.85,2.84-5.44,4.49-10.76,4.97l-4.55,0.19c-0.14,3.37-0.21,5.99-0.21,7.85l0.02,0.65v1.7
								c-0.06,0.19-0.16,0.33-0.32,0.4l-12.02,0.19c-0.24-0.68-0.36-1.75-0.36-3.24V73.45H287.38z M305.8,64.42
								c-0.56-1.47-2.05-2.26-4.46-2.36c-0.35-0.01-0.83-0.02-1.45-0.02c-0.17,0-0.27,2.07-0.32,6.21l-0.04,1.58l0.06,1.26
								c1.72-0.03,3.25-0.4,4.56-1.09c1.31-0.7,1.97-1.98,1.97-3.83C306.12,65.38,306.01,64.79,305.8,64.42z"mask="url('#writing')" />
							<path d="M328.83,55.7l6.75-0.06c1.43,0,2.73,0.03,3.89,0.09c3.13,0.09,5.51,0.82,7.15,2.21
								c1.64,1.39,2.45,3.35,2.45,5.87c0,1.75-0.51,3.25-1.54,4.49s-2.52,2.34-4.48,3.28c3.12,0.98,5,3.37,5.66,7.16
								c0.15,0.83,0.28,1.77,0.37,2.85c0.09,1.07,0.17,1.78,0.22,2.15l0.21,1.07c0.22,1.26,0.42,2.18,0.59,2.74l-0.09,0.06
								c-2.39,0.15-4.56,0.23-6.54,0.23s-3.71-0.04-5.23-0.11h-0.13c-0.44,0-0.9-2.07-1.39-6.21l-0.11-0.91
								c-0.06-1.54-0.32-2.58-0.81-3.12s-1.32-0.8-2.51-0.8l-0.27,0.02h-1.11v6.4l0.02,0.68v3.85c-0.04,0.14-0.15,0.22-0.32,0.25
								c-1.54,0.06-3.72,0.09-6.54,0.09s-4.67,0.01-5.57,0.02l-0.13-0.04l-0.04,0.02c-0.03,0-0.07-0.02-0.13-0.06l0.04-1.9l-0.02-0.63
								l0.09-8.74v-1.54l0.02-1.58l0.02-5.45l0.02-0.72l-0.06-4.78l0.09-6.72c0-0.11,0.4-0.17,1.2-0.17l1.35,0.02h1.29l2.67,0.02
								L328.83,55.7z M336.62,62.57c-0.84-0.37-1.96-0.55-3.37-0.55h-1.22c-0.15,0.22-0.23,1.41-0.23,3.55s0.04,3.58,0.11,4.3
								c4.39-0.1,6.59-1.62,6.59-4.55C338.49,64.07,337.87,63.15,336.62,62.57z M350.13,87.53v0.02c-0.01,0-0.02-0.01-0.02-0.02H350.13z
								M350.24,87.47l-0.11,0.06v-0.04C350.13,87.48,350.16,87.47,350.24,87.47z"mask="url('#writing')" />
							<path d="M352.09,85.11c0-2.85,0.08-4.83,0.23-5.94c0-6.11,0.09-12.26,0.25-18.46c-0.04-0.88-0.06-1.77-0.06-2.67v-0.7
								c-0.06-0.43-0.09-0.82-0.09-1.16s0.04-0.55,0.11-0.65c3,0.18,5.63,0.27,7.9,0.27s3.81-0.04,4.65-0.13
								c0.03,0.04,0.05,0.16,0.06,0.36h-0.15v7.62l0.15,17.14c0,0.13,0.02,0.23,0.06,0.32v0.04c0.04,0.09,0.06,0.17,0.06,0.27l0.17,6.13
								c-0.07,0.15-0.19,0.27-0.38,0.36h-0.72l-11.79,0.19c-0.14-0.07-0.32-0.18-0.55-0.32l0.02-0.36L352.09,85.11z"mask="url('#writing')" />
							<path d="M396.08,58.33l-0.06,4.21v0.27c-0.21,1.4-1.75,3.66-4.61,6.78l-1.62,1.77c-1.81,2.13-3.28,3.89-4.4,5.26
								l-2.4,2.92h1.11l12.21-0.17c0.21,0,0.32,1.25,0.32,3.74v2.36l0.04,1.37c0,0.45-0.09,0.73-0.27,0.86
								c-0.17,0.12-0.49,0.18-0.93,0.18h-0.55l-25.23,0.22c-0.4,0-0.69-0.04-0.88-0.13s-0.4-0.28-0.61-0.59c0-3.83-0.05-6.22-0.15-7.16
								l0.34-0.27l2.97-3.6c4.35-5.45,7.72-9.61,10.12-12.48l-6.21,0.06l-1.56,0.04c-1.53,0.04-3.07,0.06-4.61,0.06
								c-0.15-0.09-0.22-0.29-0.2-0.63s0.01-0.6-0.01-0.8v-0.4l0.02-0.17v-1.24l-0.19-4.82l0.02-0.13c0.03,0,0.09,0.03,0.19,0.09
								c0.37,0,1.75,0,4.14,0s8.09-0.07,17.1-0.21l4.57-0.06c0.47,0.01,0.83,0.1,1.05,0.27c0.22,0.16,0.34,0.5,0.34,1.02l-0.04,1.18
								L396.08,58.33L396.08,58.33z"mask="url('#writing')" />
							<path d="M399.41,61.3l-0.11-5.54c0.01,0,0.06,0.02,0.15,0.06c10.66-0.18,18.7-0.28,24.1-0.29
								c0.06,0.03,0.1,0.07,0.13,0.13v5.94c0,0.57-0.01,1.05-0.04,1.41c-0.07,0.22-0.48,0.34-1.24,0.34h-0.15
								c-3.4,0-6.93-0.06-10.61-0.17c0,0.13-0.01,0.35-0.05,0.68c-0.04,0.32-0.06,0.55-0.07,0.7l-0.11,0.8
								c-0.07,0.53-0.12,1.46-0.15,2.78l6.52-0.15c1.12-0.03,1.94-0.04,2.44-0.04h0.34c0.86,0,1.42,0.02,1.68,0.07
								c0.27,0.05,0.4,0.19,0.4,0.43l-0.02,0.17v0.5l0.11,5.41l-0.02,0.02v-0.02c-4.27,0.21-7.67,0.32-10.23,0.32h-0.99v5.09l11.43-0.36
								c0.63,0,1.03,0.09,1.2,0.27c-0.01,0.37-0.02,0.88-0.02,1.56l0.15,5.73c0,0.35-0.15,0.55-0.46,0.61l-6.99-0.06
								c-1.37,0-4.11,0.05-8.21,0.15l-3.45,0.09c-2.44,0.09-4.25,0.13-5.43,0.13l-0.06-0.04h0.02c-0.14-0.14-0.27-0.27-0.4-0.4v-0.74
								l0.13-22.5L399.41,61.3z"mask="url('#writing')" />
							<path d="M76.74,108.79c0,0.52-0.24,0.9-0.72,1.14c-0.24,0.08-0.71,0.23-1.41,0.45c-0.7,0.22-1.27,0.33-1.71,0.33
								c-0.36,0-0.64-0.04-0.84-0.12c-0.4-0.36-0.88-0.62-1.44-0.78c-0.56-0.16-1.72-0.3-3.48-0.42c-0.52,0.08-0.98,0.12-1.38,0.12
								c-0.4,0-0.72,0.02-0.96,0.06c-0.32,1.04-0.72,2.02-1.2,2.94c-0.16,0.76-0.38,1.44-0.66,2.04c-0.28,0.8-0.4,1.26-0.36,1.38
								c-0.12,0.16-0.5,1.17-1.14,3.03c-0.64,1.86-1.24,3.67-1.8,5.43c-0.4,1.24-0.7,2.14-0.9,2.7c-0.2,0.4-0.45,1.03-0.75,1.89
								c-0.3,0.86-0.51,1.47-0.63,1.83l-1.5,4.14c-0.32,0.6-0.71,1.55-1.17,2.85c-0.46,1.3-0.83,2.47-1.11,3.51
								c-0.44,1.56-0.83,2.68-1.17,3.36c-0.34,0.68-0.63,1.1-0.87,1.26c-0.24,0.16-0.58,0.24-1.02,0.24c-0.64,0-1.2-0.32-1.68-0.96
								s-0.72-1.24-0.72-1.8c0-0.88,0.31-2.36,0.93-4.44c0.62-2.08,1.21-3.74,1.77-4.98c0.16-0.72,0.31-1.3,0.45-1.74
								c0.14-0.44,0.23-0.76,0.27-0.96c0.12-0.16,0.31-0.59,0.57-1.29c0.26-0.7,0.47-1.35,0.63-1.95c0.12-0.72,1.36-4.74,3.72-12.06
								l0.42-1.26c0.36-0.6,0.7-1.36,1.02-2.28c0.32-0.2,0.48-0.62,0.48-1.26c0-0.12,0.07-0.28,0.21-0.48c0.14-0.2,0.21-0.34,0.21-0.42
								c0.04-0.04,0.1-0.06,0.18-0.06c0.04,0,0.09,0.01,0.15,0.03c0.06,0.02,0.17-0.05,0.33-0.21H59.4l-2.94,0.3l-1.32,0.12
								c-2.36,0.2-3.7,0.34-4.02,0.42l-0.84,0.06c-1.24,0-2.11-0.25-2.61-0.75c-0.5-0.5-0.67-1.07-0.51-1.71
								c0.12-0.52,0.43-0.94,0.93-1.26c0.5-0.32,1.33-0.6,2.49-0.84c0.36-0.08,1.06-0.2,2.1-0.36l1.62-0.3l2.04-0.24
								c2.32-0.36,4.78-0.6,7.38-0.72c1.08-0.04,1.99-0.09,2.73-0.15c0.74-0.06,1.23-0.11,1.47-0.15c0.56,0.04,1.47,0.14,2.73,0.3
								c1.26,0.16,2.17,0.34,2.73,0.54c0.88,0.2,1.6,0.56,2.16,1.08c0.56,0.52,0.94,1.12,1.14,1.8
								C76.72,108.35,76.74,108.55,76.74,108.79z"mask="url('#writing')" />
							<path d="M65.64,140.11c-0.32-0.4-0.42-0.96-0.3-1.68c0.12-1.36,0.42-2.94,0.9-4.74l0.3-1.2c0.12-1.08,0.75-3.21,1.89-6.39
								c1.14-3.18,2.09-5.33,2.85-6.45c0.4-0.4,1.08-0.6,2.04-0.6c0.8,0,1.36,0.16,1.68,0.48c0.32,0.24,0.48,0.64,0.48,1.2
								c0,0.92-0.58,2.54-1.74,4.86l-0.6,1.26c-0.28,0.52-0.51,1-0.69,1.44c-0.18,0.44-0.17,0.64,0.03,0.6c0.44-0.76,1.51-1.8,3.21-3.12
								c1.7-1.32,2.87-2.14,3.51-2.46c0.28-0.16,0.64-0.32,1.08-0.48c0.44-0.16,0.74-0.28,0.9-0.36c0.72-0.36,1.54-0.54,2.46-0.54
								c0.64,0,1.19,0.09,1.65,0.27c0.46,0.18,0.77,0.45,0.93,0.81c0.04,0.28,0.15,0.5,0.33,0.66c0.18,0.16,0.31,0.26,0.39,0.3
								c0.12,0,0.2,0.05,0.24,0.15c0.04,0.1,0.06,0.17,0.06,0.21c0,0.32-0.28,0.73-0.84,1.23c-0.56,0.5-1.14,0.77-1.74,0.81
								c-0.44,0.04-0.9,0.14-1.38,0.3c-0.48,0.16-0.96,0.36-1.44,0.6l-1.56,0.9c-1.36,0.68-2.51,1.35-3.45,2.01
								c-0.94,0.66-1.89,1.51-2.85,2.55c-0.2,0.2-0.49,0.48-0.87,0.84c-0.38,0.36-0.71,0.78-0.99,1.26c-0.28,0.28-0.57,1.01-0.87,2.19
								c-0.3,1.18-0.45,1.91-0.45,2.19c-0.04,0.12-0.12,0.36-0.24,0.72c-0.12,0.36-0.3,0.68-0.54,0.96c-0.36,0.44-0.66,0.76-0.9,0.96
								c-0.24,0.2-0.5,0.3-0.78,0.3c-0.4,0-0.86-0.26-1.38-0.78C66.4,140.93,65.96,140.51,65.64,140.11z"mask="url('#writing')" />
							<path d="M89.28,141.07c-1.4-1.28-2.1-3.1-2.1-5.46c0-0.92,0.06-1.94,0.18-3.06c0.08-0.96,0.47-2.28,1.17-3.96
								c0.7-1.68,1.66-3.32,2.88-4.92c1.22-1.6,2.65-2.78,4.29-3.54c0.76-0.36,1.4-0.61,1.92-0.75c0.52-0.14,1.12-0.21,1.8-0.21
								c1.64,0,2.97,0.3,3.99,0.9c1.02,0.6,1.74,1.28,2.16,2.04s0.63,1.42,0.63,1.98v0.3v0.18c0,0.48-0.28,1.36-0.84,2.64
								c-0.56,1.28-1.45,2.48-2.67,3.6c-1.22,1.12-2.79,1.74-4.71,1.86c-0.96,0-1.89-0.09-2.79-0.27s-1.47-0.27-1.71-0.27
								c-0.6,0-0.98,0.22-1.14,0.66c-0.16,0.44-0.24,1.18-0.24,2.22c0.12,1.12,0.43,1.9,0.93,2.34c0.5,0.44,1.23,0.66,2.19,0.66
								c0.48,0,1.04-0.14,1.68-0.42c0.64-0.32,1.4-0.82,2.28-1.5c0.48-0.4,0.86-0.68,1.14-0.84c0.28-0.16,0.62-0.24,1.02-0.24
								c0.36,0,0.78,0.24,1.26,0.72c0.48,0.48,0.74,0.94,0.78,1.38c-0.08,0.48-0.52,1.2-1.32,2.16c-0.8,0.96-1.84,1.82-3.12,2.58
								c-1.28,0.76-2.66,1.14-4.14,1.14C92.52,142.99,90.68,142.35,89.28,141.07z M98.46,128.29c0.4-0.2,1.01-0.71,1.83-1.53
								c0.82-0.82,1.25-1.39,1.29-1.71c0-0.4-0.17-0.73-0.51-0.99c-0.34-0.26-0.81-0.43-1.41-0.51H99c-0.52,0-1.27,0.43-2.25,1.29
								c-0.98,0.86-1.83,1.85-2.55,2.97c0.04,0.2,0.34,0.37,0.9,0.51c0.56,0.14,1.16,0.21,1.8,0.21
								C97.66,128.53,98.18,128.45,98.46,128.29z"mask="url('#writing')" />
							<path d="M107.88,140.77c-0.56-0.4-0.84-0.64-0.84-0.72c0-0.16-0.09-0.39-0.27-0.69s-0.35-0.57-0.51-0.81
								c-0.32-0.32-0.1-1.36,0.66-3.12c0.76-1.76,1.78-3.84,3.06-6.24c0.24-0.24,0.55-0.66,0.93-1.26c0.38-0.6,0.63-0.94,0.75-1.02
								c0-0.16,0.06-0.31,0.18-0.45c0.12-0.14,0.26-0.21,0.42-0.21l0.48-0.96c0.16-0.36,0.94-1.22,2.34-2.58c1.4-1.36,2.5-2.28,3.3-2.76
								c0.76-0.52,1.58-0.78,2.46-0.78c0.96,0,1.78,0.28,2.46,0.84l1.62,1.38c1.2,1,1.88,1.58,2.04,1.74l2.22,1.98l-1.02,3
								c-0.36,1.08-0.54,2.1-0.54,3.06c0,1.52,0.42,2.94,1.26,4.26c0.2,0.44,0.47,0.78,0.81,1.02c0.34,0.24,0.79,0.4,1.35,0.48
								c0.88,0.16,1.32,0.6,1.32,1.32c0,0.88-0.16,1.54-0.48,1.98c-0.32,0.44-0.7,0.72-1.14,0.84c-0.64,0.08-1.08,0.12-1.32,0.12
								c-1.16,0-2.22-0.3-3.18-0.9c-0.96-0.6-1.8-1.62-2.52-3.06c-0.16-0.24-0.37-0.68-0.63-1.32c-0.26-0.64-0.41-1.08-0.45-1.32
								c-0.28,0.4-0.74,0.92-1.38,1.56c-4.12,4.16-7.46,6.24-10.02,6.24c-0.88,0-1.66-0.22-2.34-0.66
								C108.78,141.49,108.44,141.17,107.88,140.77z M115.92,134.59c0.2-0.16,0.42-0.35,0.66-0.57c0.24-0.22,0.5-0.45,0.78-0.69
								c1.12-0.92,1.99-1.69,2.61-2.31c0.62-0.62,1.25-1.37,1.89-2.25l1.32-2.16c-0.4-0.88-0.76-1.47-1.08-1.77
								c-0.32-0.3-0.64-0.45-0.96-0.45c-0.8,0-1.8,0.71-3,2.13c-1.2,1.42-2.64,3.57-4.32,6.45c-0.44,0.72-0.8,1.47-1.08,2.25
								c-0.28,0.78-0.46,1.29-0.54,1.53C112.84,136.83,114.08,136.11,115.92,134.59z"mask="url('#writing')" />
							<path d="M137.25,143.29c-1.02-0.4-1.88-0.89-2.58-1.47c-0.7-0.58-1.05-1.11-1.05-1.59c0-0.48,0.22-0.78,0.66-0.9l0.3-0.24
								c0.08-0.04,0.24-0.06,0.48-0.06c0.16-0.04,0.36-0.03,0.6,0.03c0.24,0.06,0.44,0.09,0.6,0.09c0.28,0.08,0.7,0.16,1.26,0.24
								l1.56,0.18c0.8,0,1.55-0.08,2.25-0.24c0.7-0.16,1.05-0.34,1.05-0.54c0-0.12-0.16-0.32-0.48-0.6c-0.32-0.28-0.66-0.42-1.02-0.42
								c0-0.16-0.62-0.7-1.86-1.62c-0.68-0.52-1.33-1.04-1.95-1.56c-0.62-0.52-0.93-0.9-0.93-1.14l0.06-0.12v-0.3
								c-0.28,0-0.54-0.38-0.78-1.14c-0.24-0.76-0.36-1.5-0.36-2.22c0-1.36,0.6-2.86,1.8-4.5c1.2-1.64,2.69-3.12,4.47-4.44
								c1.78-1.32,3.47-2.22,5.07-2.7c0.6-0.28,1.48-0.42,2.64-0.42c0.76,0,1.28,0.08,1.56,0.24l0.48-0.12c0.48,0,1.1,0.4,1.86,1.2
								c0.76,0.8,1.24,1.44,1.44,1.92c0,1.32-0.04,2.38-0.12,3.18c-0.08,0.8-0.28,1.2-0.6,1.2c-0.04,0-0.1,0.02-0.18,0.06
								c-0.08,0.04-0.12,0.14-0.12,0.3c0,0.56-0.49,1.18-1.47,1.86c-0.98,0.68-1.87,1.02-2.67,1.02l-0.24-0.06
								c-0.24-0.04-0.59-0.28-1.05-0.72c-0.46-0.44-0.69-0.88-0.69-1.32c0-0.12,0.24-0.44,0.72-0.96c0.48-0.52,0.89-1.03,1.23-1.53
								c0.34-0.5,0.51-1.03,0.51-1.59c0-0.2-0.07-0.33-0.21-0.39c-0.14-0.06-0.41-0.09-0.81-0.09c-2.2,1-3.78,1.94-4.74,2.82
								c-0.12,0.24-0.23,0.42-0.33,0.54c-0.1,0.12-0.23,0.18-0.39,0.18c0-0.04-0.02-0.06-0.06-0.06c-0.08,0-0.34,0.23-0.78,0.69
								c-0.44,0.46-0.85,0.99-1.23,1.59c-0.38,0.6-0.61,1.16-0.69,1.68l-0.12,0.18v0.3c-0.04,0.08-0.06,0.24-0.06,0.48
								c0,0.36,0.16,0.73,0.48,1.11c0.32,0.38,0.96,0.91,1.92,1.59c1.8,1.36,3.03,2.37,3.69,3.03c0.66,0.66,1.07,1.25,1.23,1.77
								c0.36,0.72,0.54,1.36,0.54,1.92c0,1.36-0.71,2.41-2.13,3.15c-1.42,0.74-3.35,1.11-5.79,1.11
								C139.26,143.89,138.27,143.69,137.25,143.29z"mask="url('#writing')" />
							<path d="M168.51,141.46c-0.22-0.58-0.49-1.47-0.81-2.67c-0.08-0.24-0.12-0.59-0.12-1.05c0-0.46,0-0.79,0-0.99
								c-0.16,0.36-0.36,0.75-0.6,1.17c-0.24,0.42-0.48,0.71-0.72,0.87c-0.92,0.92-1.84,1.6-2.76,2.04c-1.28,0.8-2.38,1.2-3.3,1.2
								c-1.12,0-2.08-0.52-2.88-1.56c-1.28-1.04-1.92-2.28-1.92-3.72c0-0.2,0.04-0.58,0.12-1.14c0.08-1.48,0.29-2.9,0.63-4.26
								c0.34-1.36,0.99-3.1,1.95-5.22l1.8-3.6l0.96-1.86c0.2-0.08,0.52-0.12,0.96-0.12c0.64,0,1.24,0.12,1.8,0.36
								c0.56,0.24,0.86,0.56,0.9,0.96c0.12,0.16,0.18,0.46,0.18,0.9c0,1.28-0.54,2.98-1.62,5.1l-0.48,1.14
								c-0.72,1.72-1.24,3.06-1.56,4.02c-0.32,0.96-0.48,1.76-0.48,2.4s0.24,1.06,0.72,1.26c0.2,0.12,0.44,0.18,0.72,0.18
								c0.8,0,1.77-0.62,2.91-1.86c1.14-1.24,2.31-3.32,3.51-6.24c0.56-1.36,0.9-2.76,1.02-4.2c-0.08-0.44,0.04-0.92,0.36-1.44
								c0.32-0.52,0.74-0.94,1.26-1.26c0.16-0.24,0.38-0.43,0.66-0.57c0.28-0.14,0.46-0.13,0.54,0.03l0.96-0.06
								c0.12-0.04,0.31,0.14,0.57,0.54c0.26,0.4,0.45,0.64,0.57,0.72c0.4,0.32,0.6,0.96,0.6,1.92c0,1.32-0.48,3.14-1.44,5.46
								c-0.76,1.6-1.27,2.83-1.53,3.69c-0.26,0.86-0.41,1.69-0.45,2.49c0,0.84,0.07,1.49,0.21,1.95c0.14,0.46,0.49,0.93,1.05,1.41
								c0.64,0.44,0.9,1.48,0.78,3.12c-0.08,0.28-0.3,0.5-0.66,0.66c-0.36,0.16-0.8,0.24-1.32,0.24c-0.88,0-1.62-0.2-2.22-0.6
								C169.02,142.51,168.73,142.04,168.51,141.46z"mask="url('#writing')" />
							<path d="M177.24,140.11c-0.32-0.4-0.42-0.96-0.3-1.68c0.12-1.36,0.42-2.94,0.9-4.74l0.3-1.2c0.12-1.08,0.75-3.21,1.89-6.39
								c1.14-3.18,2.09-5.33,2.85-6.45c0.4-0.4,1.08-0.6,2.04-0.6c0.8,0,1.36,0.16,1.68,0.48c0.32,0.24,0.48,0.64,0.48,1.2
								c0,0.92-0.58,2.54-1.74,4.86l-0.6,1.26c-0.28,0.52-0.51,1-0.69,1.44c-0.18,0.44-0.17,0.64,0.03,0.6c0.44-0.76,1.51-1.8,3.21-3.12
								c1.7-1.32,2.87-2.14,3.51-2.46c0.28-0.16,0.64-0.32,1.08-0.48c0.44-0.16,0.74-0.28,0.9-0.36c0.72-0.36,1.54-0.54,2.46-0.54
								c0.64,0,1.19,0.09,1.65,0.27c0.46,0.18,0.77,0.45,0.93,0.81c0.04,0.28,0.15,0.5,0.33,0.66c0.18,0.16,0.31,0.26,0.39,0.3
								c0.12,0,0.2,0.05,0.24,0.15c0.04,0.1,0.06,0.17,0.06,0.21c0,0.32-0.28,0.73-0.84,1.23c-0.56,0.5-1.14,0.77-1.74,0.81
								c-0.44,0.04-0.9,0.14-1.38,0.3c-0.48,0.16-0.96,0.36-1.44,0.6l-1.56,0.9c-1.36,0.68-2.51,1.35-3.45,2.01
								c-0.94,0.66-1.89,1.51-2.85,2.55c-0.2,0.2-0.49,0.48-0.87,0.84c-0.38,0.36-0.71,0.78-0.99,1.26c-0.28,0.28-0.57,1.01-0.87,2.19
								c-0.3,1.18-0.45,1.91-0.45,2.19c-0.04,0.12-0.12,0.36-0.24,0.72c-0.12,0.36-0.3,0.68-0.54,0.96c-0.36,0.44-0.66,0.76-0.9,0.96
								c-0.24,0.2-0.5,0.3-0.78,0.3c-0.4,0-0.86-0.26-1.38-0.78C178,140.93,177.56,140.51,177.24,140.11z"mask="url('#writing')" />
							<path d="M200.88,141.07c-1.4-1.28-2.1-3.1-2.1-5.46c0-0.92,0.06-1.94,0.18-3.06c0.08-0.96,0.47-2.28,1.17-3.96
								c0.7-1.68,1.66-3.32,2.88-4.92c1.22-1.6,2.65-2.78,4.29-3.54c0.76-0.36,1.4-0.61,1.92-0.75c0.52-0.14,1.12-0.21,1.8-0.21
								c1.64,0,2.97,0.3,3.99,0.9c1.02,0.6,1.74,1.28,2.16,2.04s0.63,1.42,0.63,1.98v0.3v0.18c0,0.48-0.28,1.36-0.84,2.64
								c-0.56,1.28-1.45,2.48-2.67,3.6c-1.22,1.12-2.79,1.74-4.71,1.86c-0.96,0-1.89-0.09-2.79-0.27s-1.47-0.27-1.71-0.27
								c-0.6,0-0.98,0.22-1.14,0.66c-0.16,0.44-0.24,1.18-0.24,2.22c0.12,1.12,0.43,1.9,0.93,2.34c0.5,0.44,1.23,0.66,2.19,0.66
								c0.48,0,1.04-0.14,1.68-0.42c0.64-0.32,1.4-0.82,2.28-1.5c0.48-0.4,0.86-0.68,1.14-0.84c0.28-0.16,0.62-0.24,1.02-0.24
								c0.36,0,0.78,0.24,1.26,0.72c0.48,0.48,0.74,0.94,0.78,1.38c-0.08,0.48-0.52,1.2-1.32,2.16c-0.8,0.96-1.84,1.82-3.12,2.58
								c-1.28,0.76-2.66,1.14-4.14,1.14C204.12,142.99,202.28,142.35,200.88,141.07z M210.06,128.29c0.4-0.2,1.01-0.71,1.83-1.53
								c0.82-0.82,1.25-1.39,1.29-1.71c0-0.4-0.17-0.73-0.51-0.99c-0.34-0.26-0.81-0.43-1.41-0.51h-0.66c-0.52,0-1.27,0.43-2.25,1.29
								c-0.98,0.86-1.83,1.85-2.55,2.97c0.04,0.2,0.34,0.37,0.9,0.51c0.56,0.14,1.16,0.21,1.8,0.21
								C209.26,128.53,209.78,128.45,210.06,128.29z"mask="url('#writing')" />
							<path d="M222.09,143.29c-1.02-0.4-1.88-0.89-2.58-1.47c-0.7-0.58-1.05-1.11-1.05-1.59c0-0.48,0.22-0.78,0.66-0.9l0.3-0.24
								c0.08-0.04,0.24-0.06,0.48-0.06c0.16-0.04,0.36-0.03,0.6,0.03c0.24,0.06,0.44,0.09,0.6,0.09c0.28,0.08,0.7,0.16,1.26,0.24
								l1.56,0.18c0.8,0,1.55-0.08,2.25-0.24c0.7-0.16,1.05-0.34,1.05-0.54c0-0.12-0.16-0.32-0.48-0.6c-0.32-0.28-0.66-0.42-1.02-0.42
								c0-0.16-0.62-0.7-1.86-1.62c-0.68-0.52-1.33-1.04-1.95-1.56c-0.62-0.52-0.93-0.9-0.93-1.14l0.06-0.12v-0.3
								c-0.28,0-0.54-0.38-0.78-1.14c-0.24-0.76-0.36-1.5-0.36-2.22c0-1.36,0.6-2.86,1.8-4.5c1.2-1.64,2.69-3.12,4.47-4.44
								c1.78-1.32,3.47-2.22,5.07-2.7c0.6-0.28,1.48-0.42,2.64-0.42c0.76,0,1.28,0.08,1.56,0.24l0.48-0.12c0.48,0,1.1,0.4,1.86,1.2
								c0.76,0.8,1.24,1.44,1.44,1.92c0,1.32-0.04,2.38-0.12,3.18c-0.08,0.8-0.28,1.2-0.6,1.2c-0.04,0-0.1,0.02-0.18,0.06
								c-0.08,0.04-0.12,0.14-0.12,0.3c0,0.56-0.49,1.18-1.47,1.86c-0.98,0.68-1.87,1.02-2.67,1.02l-0.24-0.06
								c-0.24-0.04-0.59-0.28-1.05-0.72c-0.46-0.44-0.69-0.88-0.69-1.32c0-0.12,0.24-0.44,0.72-0.96c0.48-0.52,0.89-1.03,1.23-1.53
								c0.34-0.5,0.51-1.03,0.51-1.59c0-0.2-0.07-0.33-0.21-0.39c-0.14-0.06-0.41-0.09-0.81-0.09c-2.2,1-3.78,1.94-4.74,2.82
								c-0.12,0.24-0.23,0.42-0.33,0.54c-0.1,0.12-0.23,0.18-0.39,0.18c0-0.04-0.02-0.06-0.06-0.06c-0.08,0-0.34,0.23-0.78,0.69
								c-0.44,0.46-0.85,0.99-1.23,1.59c-0.38,0.6-0.61,1.16-0.69,1.68l-0.12,0.18v0.3c-0.04,0.08-0.06,0.24-0.06,0.48
								c0,0.36,0.16,0.73,0.48,1.11c0.32,0.38,0.96,0.91,1.92,1.59c1.8,1.36,3.03,2.37,3.69,3.03c0.66,0.66,1.07,1.25,1.23,1.77
								c0.36,0.72,0.54,1.36,0.54,1.92c0,1.36-0.71,2.41-2.13,3.15c-1.42,0.74-3.35,1.11-5.79,1.11
								C224.1,143.89,223.11,143.69,222.09,143.29z"mask="url('#writing')" />
							<path d="M257.52,142.33c-0.28-0.16-0.68-0.46-1.2-0.9c-0.8-0.84-1.42-1.71-1.86-2.61s-0.6-1.69-0.48-2.37
								c0.12-0.28,0.17-0.56,0.15-0.84c-0.02-0.28-0.03-0.46-0.03-0.54c-0.16-0.16-0.24-0.27-0.24-0.33s0.04-0.12,0.12-0.18
								c0.08-0.06,0.14-0.13,0.18-0.21c0.24,0,0.32-0.22,0.24-0.66c-0.08-0.32,0.06-1.02,0.42-2.1s0.82-2.08,1.38-3
								c0.44-0.72,1.09-1.62,1.95-2.7c0.86-1.08,1.63-1.9,2.31-2.46c2-1.72,3.8-2.76,5.4-3.12c0.8-0.2,1.58-0.3,2.34-0.3
								c1.16,0,2.06,0.32,2.7,0.96c0.88,0.36,1.52,0.88,1.92,1.56c0.4,0.68,0.66,1.58,0.78,2.7c0.04,0.24,0.06,0.64,0.06,1.2
								c0,2.96-1.12,6.04-3.36,9.24c-0.92,1.32-1.84,2.38-2.76,3.18s-2.04,1.6-3.36,2.4c-0.72,0.48-1.48,0.8-2.28,0.96
								c-0.8,0.16-1.88,0.28-3.24,0.36C258.18,142.57,257.8,142.49,257.52,142.33z M266.43,133.18c0.34-0.7,0.83-1.93,1.47-3.69
								c0.24-0.36,0.36-1.08,0.36-2.16c0-0.96-0.14-1.5-0.42-1.62c-0.2-0.16-0.44-0.24-0.72-0.24c-0.36,0-0.94,0.28-1.74,0.84
								c-1.4,1.2-2.57,2.45-3.51,3.75c-0.94,1.3-1.59,2.79-1.95,4.47c-0.04,0.16-0.06,0.4-0.06,0.72c0,1.36,0.48,2.04,1.44,2.04
								c0.92,0,2.08-0.68,3.48-2.04C265.54,134.57,266.09,133.88,266.43,133.18z"mask="url('#writing')" />
							<path d="M277.47,149.68c-0.86-0.54-1.57-1.13-2.13-1.77c-0.24-0.24-0.4-0.47-0.48-0.69c-0.08-0.22-0.12-0.51-0.12-0.87l0.12-1.5
								c0.2-0.6,0.3-1.3,0.3-2.1c0-0.32,0.14-1.33,0.42-3.03s0.5-2.93,0.66-3.69l0.18-0.72c0.32-1.32,0.56-2.54,0.72-3.66
								c0.32-1,0.59-1.99,0.81-2.97c0.22-0.98,0.33-1.65,0.33-2.01c0-0.72,0.28-1.24,0.84-1.56c0.04-0.32,0.34-0.98,0.9-1.98
								c0.96-2.72,1.67-4.67,2.13-5.85c0.46-1.18,0.95-2.21,1.47-3.09c0.72-2,1.3-3.26,1.74-3.78c0.28-0.68,1.04-1.68,2.28-3
								c1.24-1.32,2.34-2.38,3.3-3.18c2-1.04,3.68-1.56,5.04-1.56c1.16,0,2.5,0.72,4.02,2.16c0.56,0.6,0.88,1,0.96,1.2
								c0.08,0.2,0.12,0.58,0.12,1.14c-0.16,0.76-0.86,2.1-2.1,4.02c-1.24,1.92-2.02,2.9-2.34,2.94c-0.48,0.16-0.8,0.24-0.96,0.24
								c-0.36,0-0.66-0.12-0.9-0.36c-0.24-0.24-0.36-0.6-0.36-1.08c0-0.76,0.24-1.64,0.72-2.64c0.48-0.64,0.72-1.2,0.72-1.68
								c0-0.68-0.54-1.02-1.62-1.02c-0.32,0.16-0.95,0.73-1.89,1.71c-0.94,0.98-1.41,1.59-1.41,1.83c-0.04,0.08-0.15,0.29-0.33,0.63
								c-0.18,0.34-0.45,0.73-0.81,1.17c-0.72,1-1.08,1.62-1.08,1.86c0,0.52-0.12,0.78-0.36,0.78c-0.04,0.08-0.13,0.31-0.27,0.69
								c-0.14,0.38-0.33,0.79-0.57,1.23c-0.16,0.36-0.36,0.76-0.6,1.2c-0.24,0.44-0.44,0.74-0.6,0.9h0.06c-0.12,0.28-0.34,0.81-0.66,1.59
								c-0.32,0.78-0.48,1.23-0.48,1.35c0,0.16,0.24,0.24,0.72,0.24c0.88,0,2.12-0.22,3.72-0.66c0.52-0.16,0.9-0.24,1.14-0.24
								c0.28,0,0.68,0.08,1.2,0.24c0.44,0.28,0.8,0.9,1.08,1.86c0.28,0.96,0.34,1.56,0.18,1.8c-0.04,0.16-0.47,0.38-1.29,0.66
								s-1.67,0.5-2.55,0.66l-2.46,0.54c-2.28,0.48-3.42,0.8-3.42,0.96c-0.16,0-0.3,0.08-0.42,0.24c-0.12,0.16-0.18,0.32-0.18,0.48
								c0,0.56-0.08,0.98-0.24,1.26c-0.2,0.56-0.53,1.9-0.99,4.02c-0.46,2.12-0.69,3.42-0.69,3.9c-0.32,0.4-0.48,1.12-0.48,2.16
								c-0.24,0.44-0.36,0.94-0.36,1.5c0,0.24-0.02,0.44-0.06,0.6c0,0.2-0.04,0.68-0.12,1.44c-0.08,0.76-0.12,1.46-0.12,2.1l-0.18,1.74
								c-0.04,0.48-0.1,0.92-0.18,1.32c-0.08,0.4-0.22,0.7-0.42,0.9C278.88,150.41,278.33,150.22,277.47,149.68z"mask="url('#writing')" />
							<path d="M310.92,141.52c-0.8-0.9-1.3-1.67-1.5-2.31c0-0.08-0.04-0.17-0.12-0.27c-0.08-0.1-0.14-0.17-0.18-0.21
								c-0.16-0.12-0.24-0.78-0.24-1.98c0-0.96,0.05-1.96,0.15-3c0.1-1.04,0.23-1.88,0.39-2.52l1.08-3.18l0.84-2.4l1.62-3.48l-2.4-0.66
								c-0.28-0.04-0.51-0.18-0.69-0.42c-0.18-0.24-0.27-0.46-0.27-0.66c0-0.32,0.17-0.78,0.51-1.38c0.34-0.6,0.61-0.9,0.81-0.9
								c0.12,0.04,0.44,0.06,0.96,0.06c1.2,0,2.1-0.06,2.7-0.18c0.4-0.16,0.68-0.48,0.84-0.96c0.2-0.44,0.4-0.81,0.6-1.11
								c0.2-0.3,0.4-0.63,0.6-0.99c0.12-0.24,0.31-0.61,0.57-1.11c0.26-0.5,0.59-1.07,0.99-1.71c0.08-0.12,0.26-0.41,0.54-0.87
								c0.28-0.46,0.62-0.85,1.02-1.17c0.24-0.2,0.56-0.37,0.96-0.51c0.4-0.14,0.8-0.21,1.2-0.21c0.72,0,1.3,0.2,1.74,0.6
								c0.36,0.56,0.54,0.98,0.54,1.26c0,0.32-0.16,0.74-0.48,1.26c-0.24,0.48-0.64,1.12-1.2,1.92c-0.48,0.72-0.84,1.3-1.08,1.74
								c-0.4,0.52-0.6,0.92-0.6,1.2c0,0.28,0.26,0.42,0.78,0.42c0.56,0,1.5-0.12,2.82-0.36c0.2-0.04,0.48-0.06,0.84-0.06
								c0.96,0,1.64,0.28,2.04,0.84c0.4,0.56,0.6,1.26,0.6,2.1c0,0.56-0.19,0.9-0.57,1.02c-0.38,0.12-1.23,0.18-2.55,0.18
								c-1.28,0.2-2.6,0.3-3.96,0.3c-0.24,0-0.51,0.01-0.81,0.03c-0.3,0.02-0.61,0.07-0.93,0.15c-0.32,0.08-0.55,0.2-0.69,0.36
								c-0.14,0.16-0.29,0.42-0.45,0.78c-0.12,0.12-0.4,0.58-0.84,1.38c-0.56,1.16-0.92,1.86-1.08,2.1c-1.04,3.04-1.84,6.18-2.4,9.42
								c-0.04,0.24-0.06,0.54-0.06,0.9c0,0.52,0.17,0.89,0.51,1.11c0.34,0.22,1.01,0.33,2.01,0.33c1.12,0,2.18-0.14,3.18-0.42
								c1.2-0.52,2.1-0.96,2.7-1.32c0.36-0.2,0.69-0.39,0.99-0.57c0.3-0.18,0.51-0.31,0.63-0.39c0.16-0.32,0.43-0.28,0.81,0.12
								c0.38,0.4,0.67,0.86,0.87,1.38c0,0.72-0.14,1.37-0.42,1.95c-0.28,0.58-0.98,1.19-2.1,1.83c-1.28,0.8-2.6,1.38-3.96,1.74
								c-1.36,0.36-2.64,0.54-3.84,0.54c-0.68,0-1.3-0.06-1.86-0.18C312.44,142.93,311.72,142.42,310.92,141.52z"mask="url('#writing')" />
							<path d="M328.98,139.51c-0.6-0.68-0.9-1.46-0.9-2.34c0-0.96,0.6-3.42,1.8-7.38c0.2-1.04,0.64-2.38,1.32-4.02
								c0.24-0.68,0.38-1.08,0.42-1.2c0.72-2.4,1.7-5.34,2.94-8.82l0.54-1.44c1.4-3.8,2.24-6.12,2.52-6.96l0.96-2.64
								c0-0.32,0.23-0.63,0.69-0.93s0.85-0.45,1.17-0.45c0.64,0,1.26,0.42,1.86,1.26c0.6,0.84,0.92,1.72,0.96,2.64
								c0,0.48-0.1,0.92-0.3,1.32c-0.92,2-2.06,5.1-3.42,9.3c-0.6,1.76-1.2,3.43-1.8,5.01c-0.6,1.58-1.08,2.71-1.44,3.39l-0.66,1.32
								l-0.84,3.18c0,0.12-0.02,0.22-0.06,0.3c-0.04,0.08-0.06,0.14-0.06,0.18c0,0.12,0.02,0.18,0.06,0.18l1.02-1.02
								c1.16-1.16,2.54-2.34,4.14-3.54c1.32-0.92,2-1.44,2.04-1.56s0.2-0.3,0.48-0.54l0.6-0.48c1.28-0.92,2.66-1.38,4.14-1.38
								c0.44,0,0.96,0.22,1.56,0.66c0.6,0.44,1.11,1.05,1.53,1.83c0.42,0.78,0.63,1.61,0.63,2.49v0.48v0.42c0,1-0.19,2.31-0.57,3.93
								c-0.38,1.62-0.59,2.55-0.63,2.79c0.2,0,0.5-0.04,0.9-0.12c0.6,0,1.03,0.06,1.29,0.18c0.26,0.12,0.49,0.28,0.69,0.48
								s0.38,0.32,0.54,0.36c0.08,0.08,0.12,0.2,0.12,0.36c0,0.92-0.5,1.72-1.5,2.4c-1,0.68-1.96,1.06-2.88,1.14
								c-1.12,0-2.02-0.42-2.7-1.26c-0.68-0.84-1.02-1.86-1.02-3.06c0-0.48,0.02-0.96,0.06-1.44c0.04-0.48,0.08-0.9,0.12-1.26
								c0.12-0.8,0.18-1.44,0.18-1.92c0-0.52-0.05-0.87-0.15-1.05c-0.1-0.18-0.27-0.27-0.51-0.27c-0.8,0-2.19,0.73-4.17,2.19
								c-1.98,1.46-3.99,3.35-6.03,5.67c-1.28,1.88-2.5,2.82-3.66,2.82C330.24,140.59,329.58,140.19,328.98,139.51z"mask="url('#writing')" />
							<path d="M358.92,141.07c-1.4-1.28-2.1-3.1-2.1-5.46c0-0.92,0.06-1.94,0.18-3.06c0.08-0.96,0.47-2.28,1.17-3.96
								c0.7-1.68,1.66-3.32,2.88-4.92c1.22-1.6,2.65-2.78,4.29-3.54c0.76-0.36,1.4-0.61,1.92-0.75c0.52-0.14,1.12-0.21,1.8-0.21
								c1.64,0,2.97,0.3,3.99,0.9c1.02,0.6,1.74,1.28,2.16,2.04s0.63,1.42,0.63,1.98v0.3v0.18c0,0.48-0.28,1.36-0.84,2.64
								c-0.56,1.28-1.45,2.48-2.67,3.6s-2.79,1.74-4.71,1.86c-0.96,0-1.89-0.09-2.79-0.27c-0.9-0.18-1.47-0.27-1.71-0.27
								c-0.6,0-0.98,0.22-1.14,0.66c-0.16,0.44-0.24,1.18-0.24,2.22c0.12,1.12,0.43,1.9,0.93,2.34c0.5,0.44,1.23,0.66,2.19,0.66
								c0.48,0,1.04-0.14,1.68-0.42c0.64-0.32,1.4-0.82,2.28-1.5c0.48-0.4,0.86-0.68,1.14-0.84c0.28-0.16,0.62-0.24,1.02-0.24
								c0.36,0,0.78,0.24,1.26,0.72c0.48,0.48,0.74,0.94,0.78,1.38c-0.08,0.48-0.52,1.2-1.32,2.16s-1.84,1.82-3.12,2.58
								s-2.66,1.14-4.14,1.14C362.16,142.99,360.32,142.35,358.92,141.07z M368.1,128.29c0.4-0.2,1.01-0.71,1.83-1.53
								c0.82-0.82,1.25-1.39,1.29-1.71c0-0.4-0.17-0.73-0.51-0.99c-0.34-0.26-0.81-0.43-1.41-0.51h-0.66c-0.52,0-1.27,0.43-2.25,1.29
								c-0.98,0.86-1.83,1.85-2.55,2.97c0.04,0.2,0.34,0.37,0.9,0.51c0.56,0.14,1.16,0.21,1.8,0.21
								C367.3,128.53,367.82,128.45,368.1,128.29z"mask="url('#writing')" />
							<path d="M394.65,143.29c-1.02-0.4-1.88-0.89-2.58-1.47c-0.7-0.58-1.05-1.11-1.05-1.59c0-0.48,0.22-0.78,0.66-0.9l0.3-0.24
								c0.08-0.04,0.24-0.06,0.48-0.06c0.16-0.04,0.36-0.03,0.6,0.03c0.24,0.06,0.44,0.09,0.6,0.09c0.28,0.08,0.7,0.16,1.26,0.24
								l1.56,0.18c0.8,0,1.55-0.08,2.25-0.24c0.7-0.16,1.05-0.34,1.05-0.54c0-0.12-0.16-0.32-0.48-0.6c-0.32-0.28-0.66-0.42-1.02-0.42
								c0-0.16-0.62-0.7-1.86-1.62c-0.68-0.52-1.33-1.04-1.95-1.56c-0.62-0.52-0.93-0.9-0.93-1.14l0.06-0.12v-0.3
								c-0.28,0-0.54-0.38-0.78-1.14s-0.36-1.5-0.36-2.22c0-1.36,0.6-2.86,1.8-4.5c1.2-1.64,2.69-3.12,4.47-4.44
								c1.78-1.32,3.47-2.22,5.07-2.7c0.6-0.28,1.48-0.42,2.64-0.42c0.76,0,1.28,0.08,1.56,0.24l0.48-0.12c0.48,0,1.1,0.4,1.86,1.2
								s1.24,1.44,1.44,1.92c0,1.32-0.04,2.38-0.12,3.18c-0.08,0.8-0.28,1.2-0.6,1.2c-0.04,0-0.1,0.02-0.18,0.06
								c-0.08,0.04-0.12,0.14-0.12,0.3c0,0.56-0.49,1.18-1.47,1.86c-0.98,0.68-1.87,1.02-2.67,1.02l-0.24-0.06
								c-0.24-0.04-0.59-0.28-1.05-0.72c-0.46-0.44-0.69-0.88-0.69-1.32c0-0.12,0.24-0.44,0.72-0.96c0.48-0.52,0.89-1.03,1.23-1.53
								c0.34-0.5,0.51-1.03,0.51-1.59c0-0.2-0.07-0.33-0.21-0.39c-0.14-0.06-0.41-0.09-0.81-0.09c-2.2,1-3.78,1.94-4.74,2.82
								c-0.12,0.24-0.23,0.42-0.33,0.54s-0.23,0.18-0.39,0.18c0-0.04-0.02-0.06-0.06-0.06c-0.08,0-0.34,0.23-0.78,0.69
								c-0.44,0.46-0.85,0.99-1.23,1.59s-0.61,1.16-0.69,1.68l-0.12,0.18v0.3c-0.04,0.08-0.06,0.24-0.06,0.48c0,0.36,0.16,0.73,0.48,1.11
								c0.32,0.38,0.96,0.91,1.92,1.59c1.8,1.36,3.03,2.37,3.69,3.03c0.66,0.66,1.07,1.25,1.23,1.77c0.36,0.72,0.54,1.36,0.54,1.92
								c0,1.36-0.71,2.41-2.13,3.15c-1.42,0.74-3.35,1.11-5.79,1.11C396.66,143.89,395.67,143.69,394.65,143.29z"mask="url('#writing')" />
							<path d="M414.48,141.07c-1.4-1.28-2.1-3.1-2.1-5.46c0-0.92,0.06-1.94,0.18-3.06c0.08-0.96,0.47-2.28,1.17-3.96
								c0.7-1.68,1.66-3.32,2.88-4.92c1.22-1.6,2.65-2.78,4.29-3.54c0.76-0.36,1.4-0.61,1.92-0.75c0.52-0.14,1.12-0.21,1.8-0.21
								c1.64,0,2.97,0.3,3.99,0.9c1.02,0.6,1.74,1.28,2.16,2.04s0.63,1.42,0.63,1.98v0.3v0.18c0,0.48-0.28,1.36-0.84,2.64
								c-0.56,1.28-1.45,2.48-2.67,3.6s-2.79,1.74-4.71,1.86c-0.96,0-1.89-0.09-2.79-0.27c-0.9-0.18-1.47-0.27-1.71-0.27
								c-0.6,0-0.98,0.22-1.14,0.66c-0.16,0.44-0.24,1.18-0.24,2.22c0.12,1.12,0.43,1.9,0.93,2.34c0.5,0.44,1.23,0.66,2.19,0.66
								c0.48,0,1.04-0.14,1.68-0.42c0.64-0.32,1.4-0.82,2.28-1.5c0.48-0.4,0.86-0.68,1.14-0.84c0.28-0.16,0.62-0.24,1.02-0.24
								c0.36,0,0.78,0.24,1.26,0.72c0.48,0.48,0.74,0.94,0.78,1.38c-0.08,0.48-0.52,1.2-1.32,2.16s-1.84,1.82-3.12,2.58
								s-2.66,1.14-4.14,1.14C417.72,142.99,415.88,142.35,414.48,141.07z M423.66,128.29c0.4-0.2,1.01-0.71,1.83-1.53
								c0.82-0.82,1.25-1.39,1.29-1.71c0-0.4-0.17-0.73-0.51-0.99c-0.34-0.26-0.81-0.43-1.41-0.51h-0.66c-0.52,0-1.27,0.43-2.25,1.29
								c-0.98,0.86-1.83,1.85-2.55,2.97c0.04,0.2,0.34,0.37,0.9,0.51c0.56,0.14,1.16,0.21,1.8,0.21
								C422.86,128.53,423.38,128.45,423.66,128.29z"mask="url('#writing')" />
							<path d="M433.08,140.77c-0.56-0.4-0.84-0.64-0.84-0.72c0-0.16-0.09-0.39-0.27-0.69c-0.18-0.3-0.35-0.57-0.51-0.81
								c-0.32-0.32-0.1-1.36,0.66-3.12c0.76-1.76,1.78-3.84,3.06-6.24c0.24-0.24,0.55-0.66,0.93-1.26c0.38-0.6,0.63-0.94,0.75-1.02
								c0-0.16,0.06-0.31,0.18-0.45c0.12-0.14,0.26-0.21,0.42-0.21l0.48-0.96c0.16-0.36,0.94-1.22,2.34-2.58c1.4-1.36,2.5-2.28,3.3-2.76
								c0.76-0.52,1.58-0.78,2.46-0.78c0.96,0,1.78,0.28,2.46,0.84l1.62,1.38c1.2,1,1.88,1.58,2.04,1.74l2.22,1.98l-1.02,3
								c-0.36,1.08-0.54,2.1-0.54,3.06c0,1.52,0.42,2.94,1.26,4.26c0.2,0.44,0.47,0.78,0.81,1.02c0.34,0.24,0.79,0.4,1.35,0.48
								c0.88,0.16,1.32,0.6,1.32,1.32c0,0.88-0.16,1.54-0.48,1.98c-0.32,0.44-0.7,0.72-1.14,0.84c-0.64,0.08-1.08,0.12-1.32,0.12
								c-1.16,0-2.22-0.3-3.18-0.9c-0.96-0.6-1.8-1.62-2.52-3.06c-0.16-0.24-0.37-0.68-0.63-1.32c-0.26-0.64-0.41-1.08-0.45-1.32
								c-0.28,0.4-0.74,0.92-1.38,1.56c-4.12,4.16-7.46,6.24-10.02,6.24c-0.88,0-1.66-0.22-2.34-0.66
								C433.98,141.49,433.64,141.17,433.08,140.77z M441.12,134.59c0.2-0.16,0.42-0.35,0.66-0.57s0.5-0.45,0.78-0.69
								c1.12-0.92,1.99-1.69,2.61-2.31s1.25-1.37,1.89-2.25l1.32-2.16c-0.4-0.88-0.76-1.47-1.08-1.77c-0.32-0.3-0.64-0.45-0.96-0.45
								c-0.8,0-1.8,0.71-3,2.13c-1.2,1.42-2.64,3.57-4.32,6.45c-0.44,0.72-0.8,1.47-1.08,2.25c-0.28,0.78-0.46,1.29-0.54,1.53
								C438.04,136.83,439.28,136.11,441.12,134.59z"mask="url('#writing')" />

							<defs>
								<mask id="writing">
									<path class="mask-path" fill="none" stroke="#EB158D" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M102.95,62.57c3.35,0-11.31-5.88-16-2.41c-8.07,5.99-5.39,18.91-0.59,22.83c2.66,2.18,15.66,4.97,16.59,0.66
									    c3.06-14.24-9.05-9.54-6.16-10.49c4.89-1.62,10.83,2.16,15.57-1.32c6.05-4.45,9.26-14.29,13.46-13.5
									    c12.12,2.27,13.15,9.44,13.15,9.44s-1.54,13.54-4.27,15.02c-3.43,1.87-12.44,3.47-14,0.34c-4.94-7.3-3.58-17-3.58-17l36.85-7.54
									    l-0.39,27.31l13.04-2.77l11.44-23.56c0,0,0.79,19.78,0.44,23.39c-0.53,7.62,15.77,0.85,15.77,0.85s10.42-14.39,2.44-23.13
									    c-1.31-1.43-9.95-2.13-7.52-2.67c8.15-1.82,41.29,0.23,41.29,0.23l-18.95,3.52l1.37,11.12l14.69-2.05l-16.05,8.36l1.37,5.12
									    l15.53-0.51l13.31,0.68L239.7,60.6l7.85-0.51l8.36,23.05h8.2l-1.19-26.46l30.56,2.39V85.2l2.46,0.08l1.47-26.21
									    c0,0,11.37,0.15,13.31,2.71c2.73,3.59-1.03,10.1-1.03,10.1l-10.19,2.04l26.58-15.2L324.1,84.7l4.67-0.14l-0.47-25.5
									    c0,0,13.88-0.57,15.37,2.98c3.08,7.34-4.1,9.05-4.1,9.05l-4.92,1.8c0,0,6.63,2,6.63,3.93s2.22,8.36,2.22,8.36l2.88,0.28l12.32-29.8
									    v33.11l6.72-30.05l24.87,1.37l-17.75,24.42l18.61-0.68l12.63-26.97l0.86,28.51l2.39-26.46l12.8-0.51l-11.78,11.5l12.47,1.22
									    l-12.47,11.36l11.1,0.86l23.17,2.29l-6.93-57.27L62.02,13.4l-12.97,95.05l23.05-0.95L58,116.11l-7.29,27.03l21.87-17.82
									    l-4.76,14.73l13.46-13.92l19.79-2.07L89.45,139.1l21.63,1.27l10.45-18.62l5.82,17.35l15.57,3.05l-2.46-17.63l9.25-2.23l11.27,2.1
									    l-1.31,14.72l8.48,1.24l3.27-15.5l9.94-0.69l1.05,15.06l8.67-12l22.42-4.27l-11.08,13.82l5.81,4.37l22.37-1.32l-4.99-8.49
									    l7.64-10.77l35.73,3.58l-11.05,12.36l11.64-2.14l7.49,10.22l3.03-15.46l9.68-4.38l-5.35-8.71l11.39-8.86l25.56,4.82l-7.12,8.95
									    l6.89-0.91l-10.1,20.7l20.77-1.61l8.85-31.13l3.95,22.6l3.03,8.29l23.08-14.8l-6.87,16.65h36.66l-4.37-15.76l10.16-3.42l19.4,2.86
									    l-10.32,12.04l5.57,4.92l29.58-17.8l-11.41,15.19l12-1.98"/>
								</mask>
							</defs>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Banner;