import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

// Translate
import { Translation, useTranslation } from 'react-i18next';
 
import bgBanner from '../../img/bg/8951.jpg';
import bgMain from '../../img/bg/775566-3.jpg';

function SardineinNaturalOilwithChili() {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);

    });

    return (
        <div>
            <section>
                <div className="banner-inter-market" style={{ backgroundImage: `url(${bgBanner})`, backgroundPosition: `top right` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <h3>{t("sardine.inNaturalOilChili")}</h3>
                                {/* <p>{t("quote.q05")}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-th-tuna-product">
                <div className="th-tuna-product">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-6 col-11 text-center">
                                <div className="th-tuna-product-premium"> 
                                    <img src={require("../../img/logo/omega3.svg")} className="pt-5" />
                                </div>
                                <div className="th-tuna-product-item">
                                    <img src={require("../../img/product/goldenprize-sardine-naturalChili.png")} />
                                    <p className="text-center">{t("netweight200")}</p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5 col-11">
                                <div className="product-item-Ingredients">
                                    <ul>{t("ingredients.ingredients")}
                                        <li className="ml-5 mt-4">{t("sardine.sardine")}</li>
                                        <li className="ml-5">{t("ingredients.naturalOil")}</li>
                                        <li className="ml-5">{t("ingredients.water")}, {t("ingredients.chili")}, {t("ingredients.salt")}</li> 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-40 mb-100">
                            <div className="col-lg-6 col-md-6 col-11">
                                <div className="performance-facts">
                                    <div className="performance-facts__header">
                                        <h6 className="performance-facts__title">
                                            {t("nutrition.nutrition")}
                                        </h6>
                                        <p>{t("nutrition.servingPer")}: 3</p>
                                        <p>{t("nutrition.servingSize")}: 40g. (1/3 can) {t("nutrition.draninedWeight")}</p>
                                    </div> 

                                    <table className="performance-facts__table--small small-info">
                                        <thead>
                                            <tr>
                                                <td colspan="2"></td>
                                                <th>Per serving(40g.)</th>
                                                <th className="pl-4">Per 100 g.</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.energykJ")}</b></th>
                                                <td className="text-right">492 kj</td>
                                                <td className="text-right">1230 kj</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.energykCal")}</b></th>
                                                <td>117.6 kcal</td>
                                                <td>294 kcal</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.protein")}</b></th>
                                                <td>7.6 g</td>
                                                <td>19 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.carbohydrate02")}</b></th>
                                                <td>0.68 g</td>
                                                <td>1.7 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.fat")}</b></th>
                                                <td>9.6 g</td>
                                                <td>24 g</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.saturatedFat")}</th>
                                                <td>2.72 g</td>
                                                <td>6.8 g</td>
                                            </tr> 
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.sodium")}</b></th>
                                                <td>0.22 g</td>
                                                <td>0.54 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.omega3")}</b></th>
                                                <td>0.22 g</td>
                                                <td>0.55 g</td>
                                            </tr> 
                                        </tbody>
                                    </table>
                                    <p><b>{t("nutrition.textDes")}</b></p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-6 col-11" >   
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </div>
    );
} 

export default SardineinNaturalOilwithChili;


