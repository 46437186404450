import React, { Component, useEffect } from 'react';

import { Link } from "react-router-dom";

import $ from 'jquery';
import 'slick-carousel';

import WOW from 'wowjs';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Translate
import { useTranslation } from 'react-i18next';

import { Parallax, Background } from 'react-parallax';

const Sustainability = () => {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {  
        window.scrollTo(0, 0);
   
    });

    return (

        <div>
            <section>
                <Parallax
                    // blur={{ min: -3, max: 3 }}
                    strength={300}
                    bgImage={require('../img/bg/528.jpg')}
                >
                    <div style={{ height: '80vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} />

                    <div className="banner-sustainability">
                        <div className="sustainability-overlay">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-12">
                                        <div className="text-title">
                                            <h1>{t('translation:sustainability.sustainability')}</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-10">
                                        <div className="text-content">
                                            <p>{t('translation:sustainability.quote01')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Parallax>
            </section>


            <section>
                <div className="from-the-ocean">
                    <div className="from-the-ocean-img"></div>
                    <div className="text-content">
                        <h5>{t('translation:sustainability.text01')}</h5>
                        <p className="mt-4">{t('translation:sustainability.paragraph01')}</p>
                        <p>{t('translation:sustainability.paragraph02')}</p>
                        <p>{t('translation:sustainability.paragraph03')}</p>
                    </div>
                </div>
            </section>


            <section>
                <div className="sustainability-quote">
                    <div className="container">
                        <div className="row justify-content-center align-content-center text-center">
                            <div className="col-10">
                                <h5>{t('translation:sustainability.quote02')}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="caring-for-peple">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-12 text-content">
                                <h5>{t('translation:sustainability.text02')}</h5>
                                <p className="mt-4">{t('translation:sustainability.paragraph04')}</p>
                            </div>
                            <div className="col-lg-5 col-12">
                                <div className="caring-for-peple-img">
                                    <img src={require("./../img/bg/19723595.png")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default Sustainability;