import React, { Component, useRef, useEffect } from "react";

import { Link } from "react-router-dom";

// import $ from 'jquery';
import "slick-carousel";

import { Controller, Scene } from "react-scrollmagic";

// import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Translate
import { useTranslation } from "react-i18next";

import { ExternalLink } from "react-external-link";

// GSAP ScrollTrigger
import { gsap, TimelineLite, TweenMax, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const OurStory = () => {
  const { t, i18n } = useTranslation(["translation"]);

  let app = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    // gsap.from(".our-story-title", {
    //     delay: "0.2",
    //     duration: 0.5,
    //     x: "100px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".our-story-title",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from(".our-story-text-01", {
    //     delay: "0.5",
    //     duration: 1,
    //     y: "50px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".our-story-text-01",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from(".our-story-text-02", {
    //     delay: "1",
    //     duration: 1,
    //     y: "100px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".our-story-text-02",
    //         start: "top 90%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from(".our-story-text-03", {
    //     delay: "0.2",
    //     duration: 1,
    //     y: "100px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".our-story-text-03",
    //         start: "top 90%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from(".our-story-text-04", {
    //     delay: "0.8",
    //     duration: 1,
    //     y: "100px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".our-story-text-04",
    //         start: "top 90%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none reset",
    //     },
    // });

    // //  OUR TIMELINE
    // gsap.from(".timeline-text", {
    //     delay: "0.2",
    //     duration: 1,
    //     y: "-80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".timeline-text",
    //         start: "top 60%",
    //         end: "bottom 10%",
    //         markers: false,
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from("#timeline-content-01", {
    //     delay: "0.4",
    //     duration: 1,
    //     x: "-80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: "#timeline-content-01",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from("#timeline-content-02", {
    //     delay: "0.6",
    //     duration: 1,
    //     x: "80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: "#timeline-content-02",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from("#timeline-content-03", {
    //     delay: "0.8",
    //     duration: 1,
    //     x: "-80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: "#timeline-content-03",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from("#timeline-content-04", {
    //     delay: "1",
    //     duration: 1,
    //     x: "80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: "#timeline-content-04",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from("#timeline-content-05", {
    //     delay: "1.2",
    //     duration: 1,
    //     x: "-80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: "#timeline-content-05",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from("#timeline-content-06", {
    //     delay: "1.4",
    //     duration: 1,
    //     x: "80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: "#timeline-content-06",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });
    // gsap.from("#timeline-content-07", {
    //     delay: "1.6",
    //     duration: 1,
    //     x: "-80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: "#timeline-content-07",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none none",
    //     },
    // });

    // // OUR PRODUCTS
    // gsap.from(".our-products-title", {
    //     delay: "0.4",
    //     duration: 1,
    //     y: "80px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".our-products-title",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none reset",
    //     },
    // });
    // gsap.from(".our-products-text", {
    //     delay: "0.8",
    //     duration: 3,
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".our-products-text",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none reset",
    //     },
    // });

    // GOLDEN PRIZE Markets
    // gsap.from(".golden-prize-markets-title", {
    //     delay: "2.5",
    //     duration: 2.5,
    //     y: "40px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".golden-prize-markets-title",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none reset",
    //     },
    // });
    // gsap.from(".golden-prize-markets-text", {
    //     delay: "0.2",
    //     duration: 2,
    //     y: "20px",
    //     opacity: 0,
    //     ease: "Power3.easeOut",
    //     scrollTrigger: {
    //         trigger: ".golden-prize-markets-text",
    //         start: "top 70%",
    //         end: "bottom 60%",
    //         toggleActions: "restart none none reset",
    //     },
    // });
  });

  return (
    <div>
      <section className="golden-prize-our-story">
        <div className="container-fluid">
          <div className="our-story">
            <div className="row justify-content-between justify-content-md-center justify-content-sm-center">
              <div className="col-lg-5 col-md-10 col-12 mt-5">
                <h2 className="our-story-title">
                  <span>{t("translation:our")}</span>
                  <br />
                  {t("translation:ourStory.ourStory")}
                </h2>
                <p className="our-story-text-01 mt-5">
                  {t("translation:ourStory.paragraph01")}
                </p>
                <p className="our-story-text-02 mt-4">
                  {t("translation:ourStory.paragraph02")}
                </p>
              </div>
              <div className="col-lg-5 col-md-10 col-10">
                <div className="our-story-img">
                  <img src={require("./../img/opened-cans-top-view.png")} />
                </div>
              </div>
            </div>

            <div className="row justify-content-center align-content-center">
              <div className="col-lg-6 col-md-10 col-12">
                <div className="our-story-img-02">
                  <img src={require("./../img/canned-sardines-tuna.png")} />
                </div>
              </div>
              <div className="col-lg-5 col-md-10 col-12">
                <p className="our-story-text-03 mt-5">
                  {t("translation:ourStory.paragraph03")}
                </p>
                <p className="our-story-text-04 mt-4">
                  {t("translation:ourStory.paragraph04")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="golden-prize-our-timeline">
        <div class="our-timeline">
          <div className="timeline-text text-center">
            <h2>{t("translation:ourTimeline.ourTimeline")}</h2>
            <p>{t("translation:ourTimeline.text")}</p>
          </div>
          <ul>
            <li id="timeline-content-01">
              <div class="timeline-content">
                <h5>{t("translation:ourTimeline.1997")}</h5>
                <p>{t("translation:ourTimeline.1997-text")}</p>
              </div>
            </li>
            <li id="timeline-content-02">
              <div class="timeline-content">
                <h5>{t("translation:ourTimeline.1998")}</h5>
                <p>{t("translation:ourTimeline.1998-text")}</p>
              </div>
            </li>
            <li id="timeline-content-03">
              <div class="timeline-content">
                <h5>{t("translation:ourTimeline.2012")}</h5>
                <p>{t("translation:ourTimeline.2012-text")}</p>
              </div>
            </li>
            <li id="timeline-content-04">
              <div className="timeline-content">
                <h5>{t("translation:ourTimeline.2017")}</h5>
                <p>{t("translation:ourTimeline.2017-text")}</p>
              </div>
            </li>
            <li id="timeline-content-05">
              <div className="timeline-content">
                <h5>{t("translation:ourTimeline.2018")}</h5>
                <p>{t("translation:ourTimeline.2018-text")}</p>
              </div>
            </li>
            <li id="timeline-content-06">
              <div className="timeline-content">
                <h5>{t("translation:ourTimeline.2019")}</h5>
                <p>{t("translation:ourTimeline.2019-text")}</p>
              </div>
            </li>
            <li id="timeline-content-07">
              <div className="timeline-content">
                <h5>{t("translation:ourTimeline.2020")}</h5>
                <p>{t("translation:ourTimeline.2020-text")}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      {/* <section className="our-story-our-products">
                <div class="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="our-products-title">{t('translation:our')} {t('translation:ourProducts.ourProducts')}</h2>
                                <p className="our-products-text mt-5">{t('translation:ourProducts.paragraph01')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <section className="our-story-golden-prize-markets">
        <div className="golden-prize-markets">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6 col-12 golden-prize-markets-title">
                <h2>{t("translation:gpcb")}</h2>
                <h6>{t("translation:markets.quote")}</h6>
              </div>
              <div className="col-lg-6 col-12 golden-prize-markets-text">
                <p className="">{t("translation:markets.text")}</p>
                <ExternalLink href="http://www.goldenprize.co.th">
                  <p>- {t("translation:markets.explore")}</p>
                </ExternalLink>
              </div>
            </div>
            <img src={require("./../img/bg/994.png")} />
          </div>
        </div>

        <div className="golden-prize-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-12">
                <p>
                  <span>{t("translation:gpcb")}</span>{" "}
                  {t("translation:markets.products")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurStory;
