import React, { Component, useEffect } from 'react';
import "./../tuna/tuna.css";

import { Link } from "react-router-dom";


// Translate
import { useTranslation } from 'react-i18next';


const Tuna = () => {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);

    });

    return (
        <div>
            <section>
                <div className="tuna-banner">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-12">
                                <h1>{t('translation:gpcb')} {t('translation:tuna.tuna')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="all-tuna-products">
                    <div className="all-tuna-chunk">
                        <div className="container">
                            <h4><span class="circle-sketch-highlight">{t("translation:tuna.chunk")}</span></h4>
                            <div className="row pt-50">
                                <div className="col-lg-4 col-md-4 col-12 tuna-chunk">
                                    <Link to="/tuna_chunk_in_spring_water">
                                        <img src={require("../../img/product/goldenprize-tuna-springwater-lid.png")} />
                                        <p>{t("translation:tuna.chunkinSpringWater")}</p>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 tuna-chunk">
                                    <Link to="/tuna_chunk_in_brine">
                                        <img src={require("../../img/product/goldenprize-tuna-brine-lid.png")} />
                                        <p>{t("translation:tuna.chunkinBrine")}</p>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 tuna-chunk">
                                    <Link to="/tuna_chunk_in_soya_bean_oil">
                                        <img src={require("../../img/product/goldenprize-tuna-soyabean-lid.png")} />
                                        <p>{t("translation:tuna.chunkSoyaBean")}</p>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 tuna-chunk">
                                    <Link to="/tuna_chunk_in_olive_oil">
                                        <img src={require("../../img/product/goldenprize-tuna-oliveoil-lid.png")} />
                                        <p>{t("translation:tuna.chunkinOliveOil")}</p>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 tuna-chunk">
                                    <Link to="/tuna_chunk_in_tomato_sauce">
                                        <img src={require("../../img/product/goldenprize-tuna-tomato-lid.png")} />
                                        <p>{t("translation:tuna.chunkinTomato")}</p>
                                    </Link>
                                </div>
                                <div className="col-4 col-md-4 col-12"></div>
                            </div>
                        </div>
                    </div>

                    <div className="all-tuna-flakes">
                        <div className="container">
                            <h4>{t("translation:tuna.flakes")}</h4>
                            <div className="row pt-50">
                                <div className="col-lg-4 col-md-4 col-12 tuna-flakes">
                                    <Link to="/tuna_flakes_in_soya_bean_oil">
                                        <img src={require("../../img/product/goldenprize-tuna-flakes-soyaben-lid.png")} />
                                        <p>{t("translation:tuna.flakesinSoyaBean")}</p>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 tuna-flakes">
                                    <Link to="/tuna_flakes_in_brine">
                                        <img src={require("../../img/product/goldenprize-tuna-flakes-brine-lid.png")} />
                                        <p>{t("translation:tuna.flakesinBrine")}</p>
                                    </Link>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 tuna-flakes">
                                    <Link to="/tuna_flakes_in_oil_with_red_chili">
                                        <img src={require("../../img/product/goldenprize-tuna-flakes-oil-lid.png")} />
                                        <p>{t("translation:tuna.flakesinOilwithRedChili")}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="all-tuna-added">
                        <div className="container">
                            <h4>{t("translation:tuna.valueAdded")}</h4>
                            <div className="row pt-50">
                                <div className="col-lg-4 col-md-4 col-12 tuna-added">
                                    <Link to="/tuna_spread_in_mayonnaise">
                                        <img src={require("../../img/product/goldenprize-tuna-mayonnaise-lid.png")} />
                                        <p>{t("translation:tuna.spreadinMayonnaise")}</p>
                                    </Link> 
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 tuna-added">
                                    <Link to="/tuna_salad_with_vegetables_maxican_style">
                                        <img src={require("../../img/product/goldenprize-tuna-salad-lid.png")} />
                                        <p>{t("translation:tuna.saladinVegetablesMexican")}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Tuna;