import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

// Translate
import { Translation, useTranslation } from 'react-i18next';


import bgBanner from '../../img/bg/1537.jpg';
import bgMain from '../../img/bg/775566-3.jpg';

function TunaChunkinSoyabean() {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);

    });

    return (
        <div>
            <section>
                <div className="banner-thai-market-tuna" style={{ backgroundImage: `url(${bgBanner})` }}>
                    <div className="container">
                        <div className="row pt-250">
                            <div className="col-lg-6 col-12">
                                <h3 className="mt-5">{t("tuna.chunkSoyaBean")}</h3>
                                {/* <p>{t("quote.q05")}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-th-tuna-product">
                <div className="th-tuna-product">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-6 col-11 text-center">
                                <div className="th-tuna-product-premium">
                                    <img src={require("../../img/logo/premium.svg")} />
                                    <img src={require("../../img/logo/omega3.svg")} className="pt-5" />
                                </div>
                                <div className="th-tuna-product-item">
                                    <img src={require("../../img/product/goldenprize-tuna-soyabean-lid.png")} />
                                    <p className="text-center">{t("netweight185")}</p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5 col-11">
                                <div className="product-item-Ingredients">
                                    <ul>{t("ingredients.ingredients")}
                                        <li className="ml-5 mt-4">{t("ingredients.tunaChunk")} 70%</li>
                                        <li className="ml-5">{t("ingredients.soyaBeanOil")}, {t("ingredients.water")}, {t("ingredients.salt")}</li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-40">
                            <div className="col-lg-6 col-md-6 col-11">
                                <div className="performance-facts">
                                    <div className="performance-facts__header">
                                        <h6 className="performance-facts__title">
                                            {t("nutrition.nutrition")}
                                        </h6>
                                        <p>{t("nutrition.servingPer")}: 3</p>
                                        <p>{t("nutrition.servingSize")}: 43g. (1/3 can) {t("nutrition.draninedWeight")}</p>
                                    </div>

                                    <table className="performance-facts__table--small small-info">
                                        <thead>
                                            <tr>
                                                <td colspan="2"></td>
                                                <th>Per serving(43g.)</th>
                                                <th className="pl-4">Per 100g.</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.energykJ")}</b></th>
                                                <td className="text-right">344 kj</td>
                                                <td className="text-right">766 kj</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.energykCal")}</b></th>
                                                <td>79 kcal</td>
                                                <td>183 kcal</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.protein")}</b></th>
                                                <td>10.4 g</td>
                                                <td>24.1 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.carbohydrate02")}</b></th>
                                                <td>0 g</td>
                                                <td>0 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.fat")}</b></th>
                                                <td>4.2 g</td>
                                                <td>9.6 g</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.saturatedFat")}</th>
                                                <td>0.69 g</td>
                                                <td>1.6 g</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.saturatedTransFat")}</th>
                                                <td>0 g</td>
                                                <td>0 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.sodium")}</b></th>
                                                <td>0.15 g</td>
                                                <td>0.34 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.omega3")}</b></th>
                                                <td>0.24 g</td>
                                                <td>0.55 g</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><b>{t("nutrition.textDes")}</b></p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-6 col-11">
                                <div className="tuna-product-list">
                                    <ul>
                                        <li>
                                            {t("nutrition.wildCaught")}
                                        </li>
                                        <li>
                                            {t("nutrition.noArtificial")}
                                        </li>
                                        <li>
                                            {t("nutrition.goodSource")}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tuna-food" style={{ backgroundImage: `url(${bgMain})` }}>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-11">
                            <div className="tuna-food-01 align-items-center text-white">
                                <img src={require("../../img/tuna/salad-vegetable.png")} />
                                <p>{t("food.menu1")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-11 text-right text-white">
                            <div className="tuna-food-04 align-items-center">
                                <img src={require("../../img/tuna/tuna-pizza.png")} />
                                <p>{t("food.menu2")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-11">
                            <div className="tuna-food-03 align-items-center text-white">
                                <img src={require("../../img/tuna/macaroni.png")} />
                                <p>{t("food.menu6")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </div>
    );
}


export default TunaChunkinSoyabean;


