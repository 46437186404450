import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import $ from 'jquery';

// Translate
import { Translation, useTranslation } from 'react-i18next';

import BannerSardine1 from "../../img/bg/sardine-2.jpg";
import bgJapanMiso from "../../img/japanese/bg-japan-miso.png";
import bgJapanSoysauce from "../../img/japanese/bg-japan-soysauce.png";
import bgJapanKabayaki from "../../img/japanese/bg-japan-kabayaki.png";


// GSAP ScrollTrigger
import { gsap, TimelineLite, TweenMax, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const Sardine = () => {
    const { t, i18n } = useTranslation(["translation"]);

    let app = useRef(null);

    useEffect(() => {

        window.scrollTo(0, 0);

        // gsap.from(".sardine-products-text", {
        //     delay: "0.4",
        //     scale: 1,
        //     y: "50px",
        //     duration: 0.5,
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         // trigger: ".sardine-products-text",
        //         start: "top 70%",
        //         end: "bottom 40%",
        //         // markers: true,
        //         toggleActions: "play none none none",
        //     },
        // })
        // gsap.from(".sardine-product-item", {
        //     delay: "0.4",
        //     scale: 1,
        //     duration: 1.5,
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".sardine-product-item",
        //         start: "top 70%",
        //         end: "bottom 40%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })

        // // Sardine Japanese
        // gsap.from(".a-taste-of-japanese-delights", {
        //     delay: "0.8",
        //     scale: 1,
        //     y: "50px",
        //     duration: 0.5,
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".a-taste-of-japanese-delights",
        //         start: "top 80%",
        //         end: "bottom 40%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".a-taste-of-japanese-delights-title", {
        //     delay: "0.4",
        //     scale: 1,
        //     duration: 1,
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".a-taste-of-japanese-delights-title",
        //         start: "top 80%",
        //         end: "bottom 40%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".box-1", {
        //     delay: "0.4",
        //     scale: 1,
        //     y: "-50px",
        //     duration: 0.5,
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".box-1",
        //         start: "top 70%",
        //         end: "bottom 40%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".box-2", {
        //     delay: "0.6",
        //     scale: 1,
        //     y: "-50px",
        //     duration: 0.5,
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".box-2",
        //         start: "top 70%",
        //         end: "bottom 40%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })

        // gsap.from(".sardines-japanese-title", {
        //     delay: "0.2",
        //     scale: 1,
        //     y: "-50px",
        //     duration: 0.5,
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".sardines-japanese-title",
        //         start: "top 70%",
        //         end: "bottom 10%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".sardines-japanese-text", {
        //     delay: "0.4",
        //     duration: 0.8,
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".sardines-japanese-text",
        //         start: "top 70%",
        //         end: "bottom 10%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })

        // gsap.from(".miso-img", {
        //     delay: "0.4",
        //     duration: 0.5,
        //     x: "-80px",
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".miso-img",
        //         start: "top 70%",
        //         end: "bottom 10%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".miso-text", {
        //     delay: "0.4",
        //     duration: 0.5,
        //     y: "-70px",
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".miso-text",
        //         start: "top 70%",
        //         end: "bottom 10%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".soysauce-text", {
        //     delay: "0.4",
        //     duration: 0.5,
        //     x: "50px",
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".soysauce-text",
        //         start: "top 70%",
        //         end: "bottom 10%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".soysauce-img", {
        //     delay: "0.4",
        //     duration: 0.5,
        //     x: "-70px",
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".soysauce-img",
        //         start: "top 70%",
        //         end: "bottom 10%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".kabayaki-img", {
        //     delay: "0.4",
        //     duration: 0.5,
        //     y: "70px",
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".kabayaki-img",
        //         start: "top 70%",
        //         end: "bottom 10%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })
        // gsap.from(".kabayaki-text", {
        //     delay: "0.4",
        //     duration: 0.5,
        //     x: "50px",
        //     opacity: 0,
        //     ease: "easeOut",
        //     scrollTrigger: {
        //         trigger: ".kabayaki-text",
        //         start: "top 70%",
        //         end: "bottom 10%",
        //         // markers: true,
        //         toggleActions: "play none none reset",
        //     },
        // })

    });
    return (
        <div>
            <section>
                <div className="goldnpize-products-banner" style={{ backgroundImage: `url(${BannerSardine1})`, height: `70vh`, backgroundPosition:`left`  }}>
                    <div className="text-content text-center">
                        <h2>{t("gpcb")}</h2>
                        <h1>{t("sardine.sardine")}</h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="sardine-products">
                    <div className="container-fluid">
                        {/* <div className="row justify-content-center text-center mb-5">
                            <div className="col-8">
                                <div className="sardine-products-text">
                                    <p><span>{t('translation:gpcb')}</span> {t('translation:sardine.text')}</p>
                                </div>
                            </div>
                        </div> */}

                        <div className="row text-center">
                            <div className="offset-lg-2 col-lg-2 col-12">
                                <div className="sardine-product-item">
                                    <Link to="/sardine_in_tomato">
                                        <img src={require("../../img/product/goldenprize-sardine-tomato.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:sardine.inTomato')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                <div className="sardine-product-item">
                                    <Link to="/sardine_in_brine">
                                        <img src={require("../../img/product/goldenprize-sardine-brine.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:sardine.inBrine')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                <div className="sardine-product-item">
                                    <Link to="/sardine_in_natural_oil">
                                        <img src={require("../../img/product/goldenprize-sardine-natural.png")} />
                                        <div class="overlay">

                                            <p><span>{t('translation:sardine.inNaturalOil')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                <div className="sardine-product-item">
                                    <Link to="/sardine_in_natural_oil_with_chili">
                                        <img src={require("../../img/product/goldenprize-sardine-naturalChili.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:sardine.inNaturalOilChili')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="row text-center mb-80">
                            <div className="offset-lg-2 col-lg-2 col-12">
                                <div className="sardine-product-item">
                                    <Link to="/sardine_in_soy_sauce">
                                        <img src={require("../../img/product/goldenprize-sardine-soysauce.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:sardine.inSoySauce')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="sardine-th-banner">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-8">
                                <div className="sardine-th-content">
                                    <h2>{t('translation:sardine.inTomato')}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section>
                <div className="a-taste-of-japanese-delights-banner">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="offset-lg-1 offset-md-1 col-8">
                                <div className="a-taste-of-japanese-delights">
                                    <h2>{t('translation:japan.quote')}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="offset-lg-1 offset-md-1 col-lg-7 col-10">
                                <div className="a-taste-of-japanese-delights-title">
                                    <h3>{t('translation:japan.text01')}</h3>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="a-taste-of-japanese-delights-text">
                                            <p className="box-1"><span>{t('translation:japan.text02')}</span> {t('translation:japan.text03')}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 mt-4">
                                        <div className="a-taste-of-japanese-delights-text">
                                            <p className="box-2">{t('translation:japan.text04')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section>
                <div className="sardines-japanese">
                    <div className="container-fluid">
                        <div className="row justify-content-end">
                            <div className="col-6">
                                <div className="sardines-japanese-title">
                                    <h2>{t("translation:japan.sardinesJapan")}</h2>
                                </div>
                                <div className="sardines-japanese-text">
                                    <p className="pt-5">
                                        {t("translation:japan.sardinesJapanIntro")}
                                    </p>
                                    <p className="pt-3">
                                        {t("translation:japan.ourSardinesJapan")}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="sardines-japanese-products"> 
                    <div className="sardines-japanese-products-items" style={{ backgroundImage: `url(${bgJapanSoysauce})` }}>
                        <div className="container">
                            <div className="row justify-content-lg-center align-items-center">
                                <div className="col-lg-4 col-12 soysauce-text">
                                    <p className="mt-3">{t("translation:japan.inSoySauceText")}</p>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="soysauce-img text-center">
                                        <img src={require("../../img/japanese/sardines-soy-sauce-lid.png")} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 soysauce-text">
                                    <h3>{t("translation:japan.inSoySauce")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sardines-japanese-products-items" style={{ backgroundImage: `url(${bgJapanMiso})` }}>
                        <div className="container">
                            <div className="row justify-content-lg-center align-items-center"> 
                                <div className="col-lg-4 col-12 miso-text">
                                    <p className="mt-3">{t("translation:japan.inMisoText")}</p>
                                </div>
                                <div className="col-lg-4 col-12 text-center">
                                    <div className="miso-img">
                                        <img src={require("../../img/japanese/sardines-miso-lid.png")} />
                                    </div>
                                </div>
                                  <div className="col-lg-4 col-12 miso-text">
                                    <h3>{t("translation:japan.inMiso")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sardines-japanese-products-items" style={{ backgroundImage: `url(${bgJapanKabayaki})` }}>
                        <div className="container">
                            <div className="row justify-content-lg-center align-items-center">
                                <div className="col-lg-4 col-12 kabayaki-text">
                                    <p className="mt-3">{t("translation:japan.inKabayakiText")}</p>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="kabayaki-img text-center">
                                        <img src={require("../../img/japanese/sardines-kabayaki-lid.png")} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 kabayaki-text">
                                    <h3>{t("translation:japan.inKabayaki")}</h3> 
                                </div>      
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    );

}

export default Sardine;