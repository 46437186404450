import React, { Component, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

// import your icons 
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";


import { ExternalLink } from 'react-external-link';


// Translate
import { Translation, useTranslation } from 'react-i18next';

const Contact = () => {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);

    });


    return (
        <div>
            <div className="contact-us-banner">
                <div className="">
                </div>
            </div>

            <div className="site-section golden-prize-contact-us">
                <div className="container">
                    <h1>{t("translation:contact")}</h1>
                    <div className="contact-address">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-10">
                                <div className="contact-address-text text-white">
                                    <h6>{t("translation:gpc")}</h6>
                                    <p>
                                        {t("translation:address.adrc01")}  <br />
                                        {t("translation:address.adrc02")}  <br />
                                        {t("translation:address.adrc03")}  <br />
                                        {t("translation:address.adrc04")}
                                    </p>
                                    <p className="mb-4">
                                        <FontAwesomeIcon icon={faEnvelope} className="mr-3" style={{ fontSize: "24px" }} />
                                            info@goldenprize.co.th <br />
                                        <FontAwesomeIcon icon={faPhone} className="mr-3" style={{ fontSize: "24px" }} />
                                            (662) 434 8953 Ext.6
                                    </p>


                                    {/* <Link to="http://www.goldenprize.co.th/" target="_blank">
                                        <p>
                                            <FontAwesomeIcon icon={faGlobe} className="mt-3 mr-3 gpc-social" />
                                            goldenprize.co.th
                                        </p>
                                    </Link>
                                    <Link to="facebook" target="_blank" >
                                        <p>
                                            <FontAwesomeIcon icon={faFacebook} className="mr-3 gpc-social" />
                                            Golden Prize Thailand
                                        </p>
                                    </Link>
                                    <Link to="instagram" target="_blank" >
                                        <p>
                                            <FontAwesomeIcon icon={faInstagram} className="mr-3 gpc-social" />
                                            goldenprizethailand
                                        </p>
                                    </Link> */}
                                </div>
                                <div className="contact-address-img mt-5">
                                    <img src={require("./../img/tuna-all-products.png")} />
                                </div>
                            </div>
                            <div className="contact-address-text text-white mt-5">
                                <ExternalLink href="http://www.goldenprize.co.th">
                                    <p>
                                        <FontAwesomeIcon icon={faGlobe} className="mt-3 mr-3 gpc-social" />
                                        goldenprize.co.th
                                    </p>
                                </ExternalLink> 
                                <ExternalLink href="https://www.facebook.com/goldenprizethailand/">
                                    <p>
                                        <FontAwesomeIcon icon={faFacebook} className="mt-3 mr-3 gpc-social" />
                                        goldenprize.co.th
                                    </p>
                                </ExternalLink> 
                                <ExternalLink href="https://www.instagram.com/goldenprizethailand/">
                                    <p>
                                        <FontAwesomeIcon icon={faInstagram} className="mt-3 mr-3 gpc-social" />
                                        goldenprize.co.th
                                    </p>
                                </ExternalLink> 
{/* 
                                <Link to="facebook" target="_blank" >
                                    <p>
                                        <FontAwesomeIcon icon={faFacebook} className="mr-3 gpc-social" />
                                            Golden Prize Thailand
                                        </p>
                                </Link>
                                <Link to="instagram" target="_blank" >
                                    <p>
                                        <FontAwesomeIcon icon={faInstagram} className="mr-3 gpc-social" />
                                            goldenprizethailand
                                        </p>
                                </Link>   */}
                            </div>

                            <div className="col-lg-6 col-10 text-center">


                                {/* <div className="fb-page" data-href="https://www.facebook.com/goldenprizethailand/" data-tabs="timeline" data-width="420" data-height="540" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                                    <blockquote cite="https://www.facebook.com/goldenprizethailand/" class="fb-xfbml-parse-ignore">
                                        <a href="https://www.facebook.com/goldenprizethailand/">Golden Prize Thailand</a>
                                    </blockquote>
                                </div> */}
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div >
    );
}

export default Contact;