import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";


import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Translate
import { Translation, useTranslation } from 'react-i18next';


// images 
import bgJapanMiso from "./../img/japanese/bg-japan-miso.png";
import bgJapanSoysauce from "./../img/japanese/bg-japan-soysauce.png";
import bgJapanKabayaki from "./../img/japanese/bg-japan-kabayaki.png";

function ThaiMarket() {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);

    });

    return (
        <div>
            <section>
                <div className="banner-thai-market">
                    <div className="container">
                        <div className="row">
                            <div className="col-8">
                                {/* <h1>{t("products.thaiMarket")}</h1> */}

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 200"  >
                                    <path d="M83.23,70.46c0,0.87-0.4,1.5-1.2,1.9c-0.4,0.13-1.18,0.38-2.35,0.75c-1.17,0.37-2.12,0.55-2.85,0.55
                                        c-0.6,0-1.07-0.07-1.4-0.2c-0.67-0.6-1.47-1.03-2.4-1.3c-0.93-0.27-2.87-0.5-5.8-0.7c-0.87,0.13-1.63,0.2-2.3,0.2
                                        c-0.67,0-1.2,0.03-1.6,0.1c-0.53,1.73-1.2,3.37-2,4.9c-0.27,1.27-0.63,2.4-1.1,3.4c-0.47,1.33-0.67,2.1-0.6,2.3
                                        c-0.2,0.27-0.83,1.95-1.9,5.05c-1.07,3.1-2.07,6.12-3,9.05c-0.67,2.07-1.17,3.57-1.5,4.5c-0.33,0.67-0.75,1.72-1.25,3.15
                                        c-0.5,1.43-0.85,2.45-1.05,3.05l-2.5,6.9c-0.53,1-1.18,2.58-1.95,4.75c-0.77,2.17-1.38,4.12-1.85,5.85
                                        c-0.73,2.6-1.38,4.47-1.95,5.6c-0.57,1.13-1.05,1.83-1.45,2.1c-0.4,0.27-0.97,0.4-1.7,0.4c-1.07,0-2-0.53-2.8-1.6
                                        c-0.8-1.07-1.2-2.07-1.2-3c0-1.47,0.52-3.93,1.55-7.4c1.03-3.46,2.02-6.23,2.95-8.3c0.27-1.2,0.52-2.17,0.75-2.9
                                        c0.23-0.73,0.38-1.27,0.45-1.6c0.2-0.27,0.52-0.98,0.95-2.15c0.43-1.17,0.78-2.25,1.05-3.25c0.2-1.2,2.27-7.9,6.2-20.1l0.7-2.1
                                        c0.6-1,1.17-2.27,1.7-3.8c0.53-0.33,0.8-1.03,0.8-2.1c0-0.2,0.12-0.47,0.35-0.8c0.23-0.33,0.35-0.57,0.35-0.7
                                        c0.07-0.07,0.17-0.1,0.3-0.1c0.07,0,0.15,0.02,0.25,0.05c0.1,0.03,0.28-0.08,0.55-0.35h-0.1l-4.9,0.5l-2.2,0.2
                                        c-3.93,0.33-6.17,0.57-6.7,0.7l-1.4,0.1c-2.07,0-3.52-0.42-4.35-1.25c-0.83-0.83-1.12-1.78-0.85-2.85c0.2-0.87,0.72-1.57,1.55-2.1
                                        c0.83-0.53,2.22-1,4.15-1.4c0.6-0.13,1.77-0.33,3.5-0.6l2.7-0.5l3.4-0.4c3.87-0.6,7.97-1,12.3-1.2c1.8-0.07,3.32-0.15,4.55-0.25
                                        c1.23-0.1,2.05-0.18,2.45-0.25c0.93,0.07,2.45,0.23,4.55,0.5c2.1,0.27,3.62,0.57,4.55,0.9c1.47,0.33,2.67,0.93,3.6,1.8
                                        c0.93,0.87,1.57,1.87,1.9,3C83.19,69.73,83.23,70.06,83.23,70.46z" mask="url('#writing')" />
                                    <path d="M75.52,121.66c-1-1.13-1.5-2.43-1.5-3.9c0-1.6,1-5.7,3-12.3c0.33-1.73,1.07-3.97,2.2-6.7c0.4-1.13,0.63-1.8,0.7-2
                                        c1.2-4,2.83-8.9,4.9-14.7l0.9-2.4c2.33-6.33,3.73-10.2,4.2-11.6l1.6-4.4c0-0.53,0.38-1.05,1.15-1.55c0.77-0.5,1.42-0.75,1.95-0.75
                                        c1.07,0,2.1,0.7,3.1,2.1s1.53,2.87,1.6,4.4c0,0.8-0.17,1.53-0.5,2.2c-1.53,3.33-3.43,8.5-5.7,15.5c-1,2.93-2,5.72-3,8.35
                                        c-1,2.63-1.8,4.52-2.4,5.65l-1.1,2.2l-1.4,5.3c0,0.2-0.03,0.37-0.1,0.5c-0.07,0.13-0.1,0.23-0.1,0.3c0,0.2,0.03,0.3,0.1,0.3
                                        l1.7-1.7c1.93-1.93,4.23-3.9,6.9-5.9c2.2-1.53,3.33-2.4,3.4-2.6c0.07-0.2,0.33-0.5,0.8-0.9l1-0.8c2.13-1.53,4.43-2.3,6.9-2.3
                                        c0.73,0,1.6,0.37,2.6,1.1c1,0.73,1.85,1.75,2.55,3.05c0.7,1.3,1.05,2.68,1.05,4.15v0.8v0.7c0,1.67-0.32,3.85-0.95,6.55
                                        c-0.63,2.7-0.98,4.25-1.05,4.65c0.33,0,0.83-0.07,1.5-0.2c1,0,1.72,0.1,2.15,0.3c0.43,0.2,0.82,0.47,1.15,0.8
                                        c0.33,0.33,0.63,0.53,0.9,0.6c0.13,0.13,0.2,0.33,0.2,0.6c0,1.53-0.83,2.87-2.5,4c-1.67,1.13-3.27,1.77-4.8,1.9
                                        c-1.87,0-3.37-0.7-4.5-2.1c-1.13-1.4-1.7-3.1-1.7-5.1c0-0.8,0.03-1.6,0.1-2.4c0.07-0.8,0.13-1.5,0.2-2.1c0.2-1.33,0.3-2.4,0.3-3.2
                                        c0-0.87-0.08-1.45-0.25-1.75c-0.17-0.3-0.45-0.45-0.85-0.45c-1.33,0-3.65,1.22-6.95,3.65c-3.3,2.43-6.65,5.58-10.05,9.45
                                        c-2.13,3.13-4.17,4.7-6.1,4.7C77.62,123.46,76.52,122.8,75.52,121.66z" mask="url('#writing')" />
                                    <path d="M123.02,123.76c-0.93-0.67-1.4-1.07-1.4-1.2c0-0.27-0.15-0.65-0.45-1.15c-0.3-0.5-0.58-0.95-0.85-1.35
                                        c-0.53-0.53-0.17-2.27,1.1-5.2c1.27-2.93,2.97-6.4,5.1-10.4c0.4-0.4,0.92-1.1,1.55-2.1c0.63-1,1.05-1.57,1.25-1.7
                                        c0-0.27,0.1-0.52,0.3-0.75c0.2-0.23,0.43-0.35,0.7-0.35l0.8-1.6c0.27-0.6,1.57-2.03,3.9-4.3c2.33-2.27,4.17-3.8,5.5-4.6
                                        c1.27-0.87,2.63-1.3,4.1-1.3c1.6,0,2.97,0.47,4.1,1.4l2.7,2.3c2,1.67,3.13,2.63,3.4,2.9l3.7,3.3l-1.7,5c-0.6,1.8-0.9,3.5-0.9,5.1
                                        c0,2.54,0.7,4.9,2.1,7.1c0.33,0.73,0.78,1.3,1.35,1.7c0.57,0.4,1.32,0.67,2.25,0.8c1.47,0.27,2.2,1,2.2,2.2
                                        c0,1.47-0.27,2.57-0.8,3.3c-0.53,0.73-1.17,1.2-1.9,1.4c-1.07,0.13-1.8,0.2-2.2,0.2c-1.93,0-3.7-0.5-5.3-1.5c-1.6-1-3-2.7-4.2-5.1
                                        c-0.27-0.4-0.62-1.13-1.05-2.2c-0.43-1.07-0.68-1.8-0.75-2.2c-0.47,0.67-1.23,1.53-2.3,2.6c-6.87,6.93-12.43,10.4-16.7,10.4
                                        c-1.47,0-2.77-0.37-3.9-1.1C124.52,124.96,123.96,124.43,123.02,123.76z M136.42,113.46c0.33-0.27,0.7-0.58,1.1-0.95
                                        c0.4-0.37,0.83-0.75,1.3-1.15c1.87-1.53,3.32-2.82,4.35-3.85c1.03-1.03,2.08-2.28,3.15-3.75l2.2-3.6c-0.67-1.47-1.27-2.45-1.8-2.95
                                        c-0.54-0.5-1.07-0.75-1.6-0.75c-1.33,0-3,1.18-5,3.55c-2,2.37-4.4,5.95-7.2,10.75c-0.73,1.2-1.33,2.45-1.8,3.75
                                        c-0.47,1.3-0.77,2.15-0.9,2.55C131.29,117.2,133.36,116,136.42,113.46z" mask="url('#writing')" />
                                    <path d="M169.87,124.86c-1.17-1.67-1.88-3.73-2.15-6.2c0.27-2.47,0.47-4.27,0.6-5.4c0.73-3.27,1.65-7.12,2.75-11.55
                                        c1.1-4.43,1.75-6.88,1.95-7.35c0.33-0.6,0.87-0.9,1.6-0.9c0.73,0,1.55,0.28,2.45,0.85c0.9,0.57,1.65,1.35,2.25,2.35
                                        c0.53,0.73,0.8,1.57,0.8,2.5c0,1.4-0.67,4.33-2,8.8c-0.93,3.33-1.53,5.7-1.8,7.1c-0.27,1.4-0.37,2.9-0.3,4.5
                                        c-0.27,3-0.53,5.07-0.8,6.2c-0.27,1.13-0.67,1.67-1.2,1.6C172.42,127.36,171.04,126.53,169.87,124.86z M182.62,86.16
                                        c-0.27,0-0.7-0.2-1.3-0.6c-0.67-0.07-1.55-0.63-2.65-1.7c-1.1-1.07-1.65-1.77-1.65-2.1c-0.07-0.8,0-1.95,0.2-3.45
                                        s0.58-2.85,1.15-4.05c0.57-1.2,1.32-1.83,2.25-1.9c0.47,0.07,1.1,0.22,1.9,0.45c0.8,0.23,1.7,0.55,2.7,0.95
                                        c1.27,2.53,1.9,4.7,1.9,6.5c0,2.67-1.3,4.53-3.9,5.6C183.02,86.06,182.82,86.16,182.62,86.16z" mask="url('#writing')" />
                                    <path d="M279.42,71.66c-0.67,2.13-1.18,4.25-1.55,6.35c-0.37,2.1-0.55,3.78-0.55,5.05l0.1,1c-0.4,0.87-0.73,2.2-1,4
                                        c-0.13,0.93-0.27,1.65-0.4,2.15c-0.13,0.5-0.37,0.85-0.7,1.05c0,0.27,0.05,0.7,0.15,1.3s0.15,1.1,0.15,1.5v0.7v0.5
                                        c-1.27,7.27-1.9,13.7-1.9,19.3c0,2.93,0.22,5.23,0.65,6.9c0.43,1.67,1.02,2.57,1.75,2.7c0.13,0,0.23,0.03,0.3,0.1
                                        c0.07,0.07,0.1,0.23,0.1,0.5c0,0.87-0.22,1.67-0.65,2.4c-0.43,0.73-0.92,1.42-1.45,2.05c-0.53,0.63-0.93,1.08-1.2,1.35
                                        c-0.13,0.2-0.28,0.43-0.45,0.7c-0.17,0.27-0.42,0.4-0.75,0.4c-0.47,0-1.3-0.17-2.5-0.5c-0.2-0.4-0.83-0.88-1.9-1.45
                                        c-1.07-0.57-1.93-1.72-2.6-3.45c-0.67-1.73-1-4.67-1-8.8c0-1.13,0.03-2.03,0.1-2.7v-0.3c-0.07-0.33-0.1-0.9-0.1-1.7
                                        c0-3.07,0.35-6.95,1.05-11.65c0.7-4.7,1.45-8.95,2.25-12.75c0.53-2.27,1.23-5.07,2.1-8.4c0.87-3.47,1.3-5.33,1.3-5.6l-0.1-0.1
                                        c0.13,0.2-1.33,2.88-4.4,8.05c-3.07,5.17-4.93,8.22-5.6,9.15c-0.33,0.47-0.97,1.67-1.9,3.6l-2.8,5.8
                                        c-5.47,8.13-9.67,12.2-12.6,12.2c-2.8,0-4.62-1-5.45-3c-0.83-2-1.29-3.77-1.35-5.3c-0.07-1.53-0.1-2.46-0.1-2.8
                                        c0.13-0.73,0.2-1.6,0.2-2.6v-1.1c0-0.2,0.08-0.75,0.25-1.65c0.17-0.9,0.32-1.92,0.45-3.05c0-0.4,0.33-2.47,1-6.2
                                        c0.93-4.53,1.4-7.43,1.4-8.7c0-0.27-0.03-0.43-0.1-0.5v0.2c0,0.2-0.4,0.93-1.2,2.2c-1,1.27-2.15,3.13-3.45,5.6
                                        c-1.3,2.47-3.15,6.17-5.55,11.1c-0.47,0.87-1.17,2.3-2.1,4.3c-0.2,2.07-1.6,5.83-4.2,11.3l-0.9,2.3c-2,5-3.58,8.65-4.75,10.95
                                        c-1.17,2.3-2.05,3.42-2.65,3.35c-0.33,0-1.03-0.33-2.1-1c-1.07-0.67-2.03-1.5-2.9-2.5c-0.87-1-1.3-2-1.3-3
                                        c0-0.13,0.57-1.33,1.7-3.6c1.87-3.8,2.9-6.07,3.1-6.8c0.07-0.27,0.15-0.63,0.25-1.1c0.1-0.47,0.82-1.67,2.15-3.6
                                        c0-0.4,0.32-1.42,0.95-3.05c0.63-1.63,1.48-3.52,2.55-5.65c0.53-0.8,0.8-1.57,0.8-2.3c0.6-0.73,1.37-2.37,2.3-4.9
                                        c0.33-0.93,0.68-1.83,1.05-2.7c0.37-0.87,0.75-1.53,1.15-2c0.2-0.13,0.53-0.62,1-1.45c0.47-0.83,0.83-1.48,1.1-1.95
                                        c2.2-3.87,3.9-6.73,5.1-8.6l1.2-2.2c0.27-0.73,0.52-1.13,0.75-1.2c0.23-0.07,1.32-1.9,3.25-5.5c3-4.2,4.73-6.3,5.2-6.3
                                        c0.4,0,1.12,0.23,2.15,0.7c1.03,0.47,1.97,1.03,2.8,1.7c0.83,0.67,1.25,1.27,1.25,1.8c0,0.2,0.03,0.77,0.1,1.7
                                        c0.07,0.93,0.1,1.93,0.1,3c0,2.6-1.1,11.27-3.3,26l-0.5,3.2c0.07,0.2,0.17,0.58,0.3,1.15c0.13,0.57,0.2,1.18,0.2,1.85
                                        c0.87-1.33,1.63-2.4,2.3-3.2c1.73-2.53,2.97-4.53,3.7-6c0-0.33,0.12-0.67,0.35-1c0.23-0.33,0.48-0.7,0.75-1.1
                                        c0.53-0.67,0.95-1.27,1.25-1.8c0.3-0.53,0.45-1.1,0.45-1.7c1.87-2.67,3.53-5.2,5-7.6c5.6-8.73,9.07-13.7,10.4-14.9
                                        c0.47-0.4,1.18-0.73,2.15-1c0.97-0.27,1.88-0.4,2.75-0.4c1.87,0,3.3,0.55,4.3,1.65s1.5,2.48,1.5,4.15
                                        C280.12,69.13,279.89,70.26,279.42,71.66z"mask="url('#writing')" />
                                    <path d="M285.42,123.86c-1.6-1.47-2.53-2.83-2.8-4.1c-0.13-0.67-0.2-1.23-0.2-1.7c0-2.6,0.83-5.75,2.5-9.45
                                        c1.67-3.7,3.92-7.18,6.75-10.45c2.83-3.27,5.95-5.63,9.35-7.1c1.4-0.73,2.3-1.1,2.7-1.1c0.4,0,1.3,0.37,2.7,1.1
                                        c1.33,0.33,2.4,0.7,3.2,1.1c0.8,0.4,1.53,1.03,2.2,1.9c0.87,1.73,1.5,3.87,1.9,6.4c0.4,1.4,0.6,2.4,0.6,3c0,0.53-0.2,1-0.6,1.4
                                        c-0.07,0.07-0.18,0.2-0.35,0.4c-0.17,0.2-0.25,0.35-0.25,0.45c0,0.1,0.07,0.15,0.2,0.15c0,0.07-0.03,0.22-0.1,0.45
                                        c-0.07,0.23-0.2,0.55-0.4,0.95c-0.8,2.54-1.2,4.5-1.2,5.9c0,0.47,0.07,1.03,0.2,1.7c0.67,1.73,1.5,2.6,2.5,2.6
                                        c0.93,0,2-0.6,3.2-1.8c1-0.67,2-1,3-1c0.6,0,0.9,0.17,0.9,0.5c0.07,0.07,0.2,0.17,0.4,0.3c0.2,0.13,0.4,0.2,0.6,0.2
                                        c0.27,0,0.4,0.3,0.4,0.9c0,0.53-0.12,1.23-0.35,2.1c-0.23,0.87-0.52,1.57-0.85,2.1c-0.47,0.47-0.83,0.87-1.1,1.2
                                        c-0.27,0.33-0.4,0.6-0.4,0.8l-1.5,1.4c-1.27,0.73-2.77,1.1-4.5,1.1c-1.8,0-3.23-0.37-4.3-1.1c-1.07-0.73-2.07-1.65-3-2.75
                                        c-0.93-1.1-1.53-2.08-1.8-2.95l-0.5-1l-2.9,1.9l-2.5,2.6c-0.53,0.67-1,1-1.4,1h-1c0,0.47-0.93,1.03-2.8,1.7
                                        c-1.87,0.67-3.33,1-4.4,1C288.39,125.93,287.02,125.33,285.42,123.86z M296.17,114.71c2.3-1.7,3.85-3.05,4.65-4.05
                                        c0.27-0.33,0.62-0.82,1.05-1.45c0.43-0.63,0.82-1.12,1.15-1.45c1.07-1.27,1.85-2.28,2.35-3.05c0.5-0.77,0.75-1.52,0.75-2.25
                                        c0-0.53-0.23-1.27-0.7-2.2c-0.27-0.8-0.67-1.2-1.2-1.2c-0.27,0-1.03,0.23-2.3,0.7c-1.27,0.73-2.75,2.23-4.45,4.5
                                        c-1.7,2.27-3.18,4.57-4.45,6.9c-1.27,2.33-1.9,4.03-1.9,5.1c0,0.2,0.07,0.4,0.2,0.6c0.13,0.2,0.23,0.33,0.3,0.4
                                        C292.35,117.26,293.87,116.41,296.17,114.71z"mask="url('#writing')" />
                                    <path d="M330.97,124.96c-0.97-0.27-1.55-0.5-1.75-0.7c-0.4-0.33-0.8-0.85-1.2-1.55s-0.6-1.52-0.6-2.45c0-1.46,0.53-3,1.6-4.6
                                        c0.4-0.8,1.4-3.57,3-8.3l2-5.5c0.13-1.13,0.07-1.95-0.2-2.45c-0.27-0.5-0.83-0.78-1.7-0.85c-0.73,0-1.27-0.25-1.6-0.75
                                        c-0.33-0.5-0.5-1.32-0.5-2.45c0.2-1.93,0.97-3.33,2.3-4.2c1.07-0.53,2.53-0.8,4.4-0.8c1.67,0,2.77,0.23,3.3,0.7
                                        c0.53,0.4,1,1.3,1.4,2.7c0.4,1.4,0.7,2.7,0.9,3.9c0.33-0.2,1.2-1.1,2.6-2.7l2.2-2.3c1.13-0.8,2.43-1.57,3.9-2.3
                                        c1.47-0.73,2.5-1.1,3.1-1.1c0.67,0,1.53,0.33,2.6,1c1.07,0.67,1.77,1.3,2.1,1.9c1,1.87,1.5,3.4,1.5,4.6c0,0.93-0.23,1.67-0.7,2.2
                                        c-1.8,2.27-3.8,3.4-6,3.4c-0.53,0-0.97-0.03-1.3-0.1c-0.33-0.07-0.73-0.53-1.2-1.4c-0.47-0.87-0.73-1.53-0.8-2
                                        c0-0.2-0.48,0.18-1.45,1.15c-0.97,0.97-1.58,1.58-1.85,1.85l-1.7,1.8c-1.07,1.27-2.15,2.93-3.25,5c-1.1,2.07-2.72,5.2-4.85,9.4
                                        l-1.3,3.1c-0.67,1.53-1.17,2.62-1.5,3.25c-0.33,0.63-0.73,0.95-1.2,0.95C332.69,125.36,331.94,125.23,330.97,124.96z"mask="url('#writing')" />
                                    <path d="M380.87,127.26c-0.57-0.53-0.98-1-1.25-1.4l-3.7-3.9c-0.54-1.07-1.6-2.4-3.2-4c-0.67-0.87-1.25-1.6-1.75-2.2
                                        c-0.5-0.6-0.85-0.9-1.05-0.9l-3.1,9.9c-0.47,0.87-0.93,1.42-1.4,1.65c-0.47,0.23-1.04,0.35-1.7,0.35c-0.93,0-1.92-0.37-2.95-1.1
                                        c-1.03-0.73-1.55-1.67-1.55-2.8c-0.53-1.93,1.23-7.97,5.3-18.1c0.2-0.93,0.53-2.02,1-3.25c0.47-1.23,0.8-2.11,1-2.65
                                        c1.07-3.4,2.9-8.13,5.5-14.2l1.8-4.1c1.13-2.33,2.37-4.77,3.7-7.3c3.53-6.53,5.77-10.33,6.7-11.4c0.53-0.53,1.47-0.8,2.8-0.8
                                        c1.27,0,2.17,0.27,2.7,0.8c0.47,0.47,0.82,0.88,1.05,1.25c0.23,0.37,0.35,0.78,0.35,1.25c0,1.07-0.67,2.67-2,4.8
                                        c-0.73,1.53-1.4,2.8-2,3.8c-0.53,0.73-1.17,1.8-1.9,3.2c-0.47,1.33-0.97,2-1.5,2c-0.07,0.33-0.18,0.77-0.35,1.3
                                        c-0.17,0.53-0.38,1.07-0.65,1.6c-0.2,0.67-0.53,1.47-1,2.4c-0.47,0.93-0.77,1.6-0.9,2c-0.13,0.33-0.3,0.7-0.5,1.1
                                        s-0.43,0.9-0.7,1.5c-1.53,3-2.4,5.07-2.6,6.2c0,0.07,0.03,0.1,0.1,0.1c0.13,0,0.4-0.13,0.8-0.4c0.4-0.27,0.73-0.5,1-0.7
                                        c1.6-1.33,3.28-2.5,5.05-3.5c1.77-1,3.22-1.6,4.35-1.8c0.93-0.2,1.7-0.3,2.3-0.3c0.87,0,1.67,0.13,2.4,0.4c0.73,0.4,1.4,1.23,2,2.5
                                        c0.6,1.27,1.03,2.57,1.3,3.9c0,1-0.62,2.88-1.85,5.65c-1.23,2.77-2.28,4.52-3.15,5.25c-0.47,0.2-0.82,0.38-1.05,0.55
                                        c-0.23,0.17-0.38,0.28-0.45,0.35c0,0.27-0.13,0.62-0.4,1.05c-0.27,0.43-0.63,0.75-1.1,0.95c-0.93,0.93-1.4,1.57-1.4,1.9v0.5h-0.2
                                        c-0.27,0-0.87,0.3-1.8,0.9c-0.73,0.73-1.32,1.17-1.75,1.3c-0.43,0.13-0.79,0.23-1.05,0.3c-0.13,0.13-0.42,0.28-0.85,0.45
                                        c-0.43,0.17-0.65,0.35-0.65,0.55c0,0.47,0.92,1.75,2.75,3.85c1.83,2.1,3.15,3.42,3.95,3.95c0.93,0.47,1.4,1.5,1.4,3.1
                                        c0,0.87-0.17,1.73-0.5,2.6c-0.2,0.2-0.72,0.42-1.55,0.65c-0.83,0.23-1.68,0.35-2.55,0.35c-0.87,0-1.57-0.13-2.1-0.4
                                        C381.82,128.13,381.43,127.79,380.87,127.26z M381.32,104.96c2.4-2.07,3.93-3.53,4.6-4.4c0.53-0.53,1.03-1.33,1.5-2.4
                                        c0.46-1.07,0.57-1.9,0.3-2.5c-0.07-0.2-0.87,0.13-2.4,1l-2.7,1.5c-2.67,1.53-4.85,2.9-6.55,4.1c-1.7,1.2-2.98,2.54-3.85,4
                                        c-0.2,0.67,0.03,1.35,0.7,2.05c0.67,0.7,1.43,1.05,2.3,1.05c0.47,0,0.8-0.07,1-0.2C377.22,108.43,378.92,107.03,381.32,104.96z"mask="url('#writing')" />
                                    <path d="M402.02,124.26c-2.33-2.13-3.5-5.17-3.5-9.1c0-1.53,0.1-3.23,0.3-5.1c0.13-1.6,0.78-3.8,1.95-6.6
                                        c1.17-2.8,2.77-5.53,4.8-8.2c2.03-2.67,4.42-4.63,7.15-5.9c1.27-0.6,2.33-1.02,3.2-1.25c0.87-0.23,1.87-0.35,3-0.35
                                        c2.73,0,4.95,0.5,6.65,1.5c1.7,1,2.9,2.13,3.6,3.4c0.7,1.27,1.05,2.37,1.05,3.3v0.5v0.3c0,0.8-0.47,2.27-1.4,4.4
                                        c-0.93,2.13-2.42,4.13-4.45,6c-2.03,1.87-4.65,2.9-7.85,3.1c-1.6,0-3.15-0.15-4.65-0.45c-1.5-0.3-2.45-0.45-2.85-0.45
                                        c-1,0-1.63,0.37-1.9,1.1c-0.27,0.73-0.4,1.97-0.4,3.7c0.2,1.87,0.72,3.17,1.55,3.9c0.83,0.73,2.05,1.1,3.65,1.1
                                        c0.8,0,1.73-0.23,2.8-0.7c1.07-0.53,2.33-1.37,3.8-2.5c0.8-0.67,1.43-1.13,1.9-1.4c0.47-0.27,1.03-0.4,1.7-0.4
                                        c0.6,0,1.3,0.4,2.1,1.2s1.23,1.57,1.3,2.3c-0.13,0.8-0.87,2-2.2,3.6c-1.33,1.6-3.07,3.04-5.2,4.3c-2.13,1.27-4.43,1.9-6.9,1.9
                                        C407.42,127.46,404.35,126.4,402.02,124.26z M417.32,102.96c0.67-0.33,1.68-1.18,3.05-2.55c1.37-1.37,2.08-2.32,2.15-2.85
                                        c0-0.67-0.28-1.22-0.85-1.65c-0.57-0.43-1.35-0.72-2.35-0.85h-1.1c-0.87,0-2.12,0.72-3.75,2.15c-1.63,1.43-3.05,3.08-4.25,4.95
                                        c0.07,0.33,0.57,0.62,1.5,0.85c0.93,0.23,1.93,0.35,3,0.35C415.98,103.36,416.85,103.23,417.32,102.96z"mask="url('#writing')" />
                                    <path d="M435.82,125.01c-1.33-1.5-2.17-2.78-2.5-3.85c0-0.13-0.07-0.28-0.2-0.45c-0.13-0.17-0.23-0.28-0.3-0.35
                                        c-0.27-0.2-0.4-1.3-0.4-3.3c0-1.6,0.08-3.27,0.25-5c0.17-1.73,0.38-3.13,0.65-4.2l1.8-5.3l1.4-4l2.7-5.8l-4-1.1
                                        c-0.47-0.07-0.85-0.3-1.15-0.7s-0.45-0.77-0.45-1.1c0-0.53,0.28-1.3,0.85-2.3c0.57-1,1.02-1.5,1.35-1.5c0.2,0.07,0.73,0.1,1.6,0.1
                                        c2,0,3.5-0.1,4.5-0.3c0.67-0.27,1.13-0.8,1.4-1.6c0.33-0.73,0.67-1.35,1-1.85c0.33-0.5,0.67-1.05,1-1.65
                                        c0.2-0.4,0.52-1.02,0.95-1.85c0.43-0.83,0.98-1.78,1.65-2.85c0.13-0.2,0.43-0.68,0.9-1.45c0.47-0.77,1.03-1.42,1.7-1.95
                                        c0.4-0.33,0.93-0.62,1.6-0.85c0.67-0.23,1.33-0.35,2-0.35c1.2,0,2.17,0.33,2.9,1c0.6,0.93,0.9,1.63,0.9,2.1
                                        c0,0.53-0.27,1.23-0.8,2.1c-0.4,0.8-1.07,1.87-2,3.2c-0.8,1.2-1.4,2.17-1.8,2.9c-0.67,0.87-1,1.53-1,2c0,0.47,0.43,0.7,1.3,0.7
                                        c0.93,0,2.5-0.2,4.7-0.6c0.33-0.07,0.8-0.1,1.4-0.1c1.6,0,2.73,0.47,3.4,1.4c0.67,0.93,1,2.1,1,3.5c0,0.93-0.32,1.5-0.95,1.7
                                        c-0.63,0.2-2.05,0.3-4.25,0.3c-2.13,0.33-4.33,0.5-6.6,0.5c-0.4,0-0.85,0.02-1.35,0.05c-0.5,0.03-1.02,0.12-1.55,0.25
                                        c-0.53,0.13-0.92,0.33-1.15,0.6c-0.23,0.27-0.48,0.7-0.75,1.3c-0.2,0.2-0.67,0.97-1.4,2.3c-0.93,1.93-1.53,3.1-1.8,3.5
                                        c-1.73,5.07-3.07,10.3-4,15.7c-0.07,0.4-0.1,0.9-0.1,1.5c0,0.87,0.28,1.48,0.85,1.85c0.57,0.37,1.68,0.55,3.35,0.55
                                        c1.87,0,3.63-0.23,5.3-0.7c2-0.87,3.5-1.6,4.5-2.2c0.6-0.33,1.15-0.65,1.65-0.95s0.85-0.52,1.05-0.65c0.27-0.53,0.72-0.46,1.35,0.2
                                        c0.63,0.67,1.12,1.43,1.45,2.3c0,1.2-0.23,2.29-0.7,3.25c-0.47,0.97-1.63,1.98-3.5,3.05c-2.13,1.33-4.33,2.3-6.6,2.9
                                        c-2.27,0.6-4.4,0.9-6.4,0.9c-1.13,0-2.17-0.1-3.1-0.3C438.35,127.36,437.15,126.51,435.82,125.01z"mask="url('#writing')" />

                                    <defs>
                                        <mask id="writing">
                                            <path class="mask-path" fill="none" stroke="#EB158D" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                                                M36.5,70.9l15.9-2.73c0,0,18.38-1.8,24.89,1.2c6.5,2.99-18.77,1.12-18.77,1.12s-17.86,51.87-19.52,56.87
                                                c-1.97,5.91,27.36-24.86,44.22-43.52c8.11-8.98,12.85-19.75,12.33-18.36c-5.47,14.55-14.14,37.83-17.43,51.58
                                                c-1.84,7.7,19.45-15.16,26.7-19.06c3.28-1.77,2.13,15.62,2.13,15.62v6.32l8.78-3.49c0,0,48.74-11.22,36.58-19.16
                                                c-2.75-1.79-4.67-5.74-8.27-5.49c-4.78,0.33-13.19,12.36-13.19,12.36l-7.69,13.09c0,0,3.69,5.31,5.67,5.56
                                                c6.61,0.82,24.12-15.71,24.12-15.71s-1.7,7.32,1.43,10.84c1.65,1.86,2.7,3.25,4.16,3.56c3.92,0.83,14.42,5.71,13.38,0.77
                                                c-1.83-8.63,5.17-27.97,5.17-27.97l6.28-21.95l29.52,53l15.87-36.1c0,0,15.5-36.61,15.53-28.5c0.05,16.32-7.48,56.78,0.75,48.04
                                                c11.52-12.24,27.23-56.95,30.18-43.84c1.66,7.4-2.96,20.91-4.63,32.2c-0.91,6.15-1.88,16.94-1.19,23.52
                                                c0.54,5.18,0.3,6.79,1.49,6.68c10.63-0.94,37.24-12.85,38.79-22.13c0.36-2.13,0.72-8.93-0.73-10.53c-1.75-1.92-6.86-0.1-9.13,1.15
                                                c-4.63,2.55-13.19,15.38-13.43,20.66c-0.14,3.15,1.71,4.59,4.75,5.4c4.09,1.1,10.78-8.29,15-7.91c2.31,0.21,4.82,7.06,7.11,7.4
                                                c1.91,0.28,7.83-4.16,7.83-4.16s10.3-23.23,16.99-24.14c1.56-0.21-6.2,28.02-6.2,28.02s14.73-25.12,21.29-28.61
                                                c1.66-0.88,2.02,7.48,2.02,7.48s37.31-42.64,32.7-36.74c-7.45,9.55-15.71,31.6-15.71,31.6s-11.83,33.45-9.74,29.15
                                                c5.21-10.74,7.24-20.03,12.13-24.95c2.62-2.63,12.07-8.42,15.55-7.13c1.76,0.65,1.97,5.33,1.44,7.13
                                                c-1.44,4.91-10.12,10.3-15.81,12.98c-4.43,2.09,8.78,12.44,10.94,16.27c3.93,6.95,16.71-17.43,23.55-21.55
                                                c2.65-1.6,9.64,0.15,12.3-1.44c2.34-1.4,5.91-7.15,4.99-9.72c-1.16-3.25-7.83-6.08-11.14-5.1c-5.44,1.62-12.59,10.64-13.37,16.26
                                                c-0.58,4.22-0.33,12.81,3.01,15.46c2.15,1.71,6.74,1.68,9.15,0.36c2.5-1.37,9.06-7.22,9.06-7.22s34.08-46.8,30.51-41.85
                                                c-3.93,5.45-19.73,33.42-18.42,45.03c0.27,2.42,4.28,5.91,6.65,5.35c3.73-0.88,13.03-0.3,13.79-3.94
                                                c2.02-9.62-19.61-31.47-19.61-31.47l24.36-0.72"/>
                                        </mask>
                                    </defs>
                                </svg>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-thai-market">
                <div className="thai-market">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="can-top-view">
                                    <img src={require("./../img/tuna-lid.png")} />
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-11">
                                <div className="thai-market-content">
                                    {/* <div className="thai-market-title"> */}
                                    <h2>{t("translation:timelessTuna.timelessTuna")}</h2>
                                    {/* </div> */}
                                    <div className="thai-market-line">
                                        <p>
                                            <span>{t("translation:gpcb")}</span>
                                            {t("translation:timelessTuna.text")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="thai-market-products">
                            <div className="row justify-content-around ">
                                <div className="col-lg-3 col-md-4 col-12">
                                    <div className="tuna-item">
                                        <Link to="/tuna_in_soybean_th">
                                            <img
                                                src={require("./../img/product/goldenprizeth-tuna-soybean-lid.png")}
                                            />
                                            <p>{t("translation:tuna.steakinSoybean")}</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-12">
                                    <div className="tuna-item">
                                        <Link to="/tuna_in_soybean_chili_th">
                                            <img
                                                src={require("./../img/product/goldenprizeth-tuna-soybeanchili-lid.png")}
                                            />
                                            <p>{t("translation:tuna.steakinSoybeanChili")}</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-12">
                                    <div className="tuna-item">
                                        <Link to="/tuna_in_spring_water_th">
                                            <img
                                                src={require("./../img/product/goldenprizeth-tuna-springwater-lid.png")}
                                            />
                                            <p>{t("translation:tuna.steakinSpringWater")}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-80 golden-prize-thai-sardine">
                <div className="container">
                    <div className="thai-sardine-content">
                        <div className="row justify-content-center align-items-center text-center">
                            <div className="col-lg-8 col-11">
                                <div className="sardine-title">
                                    <h4>{t("translation:sardine.quote")}</h4>
                                    <h2> {t("translation:sardine.sardine")}</h2>
                                </div>
                                <div className="sardine-text">
                                    <p className="mt-5">
                                        <span>{t("translation:gpcb")}</span>{" "}
                                        {t("translation:sardine.text")}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-10">
                                <div className="thai-sardine-img mt-5">
                                    <Link to="/sardine_in_tomato_sauce_th">
                                        <img src={require("./../img/product/goldenprize-sardine-th.png")} />
                                        <p>{t("sardine.inTomato")}</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-10">
                                <div className="thai-sardine-tomato">
                                    <img src={require("./../img/tomato.png")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-thai-mackerel mb-80" >
                <div className="container">
                    <div className="thai-mackerel-content">
                        <div className="row justify-content-center align-items-center text-center pt-50">
                            <div className="col-lg-8 col-11">
                                <div className="mackerel-title">
                                    <h4>{t("translation:mackerel.quote")}  </h4>
                                    <h2>{t("translation:mackerel.mackerel")}</h2>
                                </div>
                                <div className="mackerel-text">
                                    <p className="mt-5">
                                        <span>{t("translation:gpcb")}</span>{" "}
                                        {t("translation:mackerel.text")}{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-10 mb-80">
                                <div className="thai-mackerel-img mt-5">
                                    <Link to="/mackerel_in_tomato_sauce_th">
                                        <img src={require("./../img/product/goldenprize-mackerel-th.png")} />
                                        <p>{t("mackerel.inTomato")}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-japanese"> 
                <div className="a-taste-of-japanese-delights-banner">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-12">
                                <div className="a-taste-of-japanese-delights text-white">
                                    <h2>{t('translation:japan.quote')}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-12">
                                <div className="a-taste-of-japanese-delights-title text-white mb-5">
                                    <h3>{t('translation:japan.text01')}</h3>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-5 col-12">
                                        <div className="a-taste-of-japanese-delights-text">
                                            <p className="box-1"><span>{t('translation:japan.text02')}</span> {t('translation:japan.text03')}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-7 col-12 mt-4">
                                        <div className="a-taste-of-japanese-delights-text">
                                            <p className="box-2">{t('translation:japan.text04')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

             
                <div className="sardines-japanese">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12">
                                <div className="sardines-japanese-title">
                                    <h2>{t("translation:japan.sardinesJapan")}</h2>
                                </div>
                                <div className="sardines-japanese-text">
                                    <p className="pt-5">
                                        {t("translation:japan.sardinesJapanIntro")}
                                    </p>
                                    <p className="pt-3">
                                        {t("translation:japan.ourSardinesJapan")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sardines-japanese-products">
                    <div className="sardines-japanese-products-items" style={{ backgroundImage: `url(${bgJapanSoysauce})` }}>
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-4 col-11 soysauce-text">
                                    <p className="mt-3">{t("translation:japan.inSoySauceText")}</p>
                                </div>
                                <div className="col-lg-4 col-11">
                                    <div className="soysauce-img text-center">
                                        <img src={require("./../img/japanese/sardines-soy-sauce-lid.png")} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-10 soysauce-text">
                                    <h3>{t("translation:japan.inSoySauce")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sardines-japanese-products-items" style={{ backgroundImage: `url(${bgJapanMiso})` }}>
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-4 col-11 miso-text">
                                    <p className="mt-3">{t("translation:japan.inMisoText")}</p>
                                </div>
                                <div className="col-lg-4 col-12 text-center">
                                    <div className="miso-img">
                                        <img src={require("./../img/japanese/sardines-miso-lid.png")} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 miso-text">
                                    <h3>{t("translation:japan.inMiso")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sardines-japanese-products-items mb-80" style={{ backgroundImage: `url(${bgJapanKabayaki})` }}>
                        <div className="container">
                            <div className="row justify-content-lg-center align-items-center">
                                <div className="col-lg-4 col-12 kabayaki-text">
                                    <p className="mt-3">{t("translation:japan.inKabayakiText")}</p>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="kabayaki-img text-center">
                                        <img src={require("./../img/japanese/sardines-kabayaki-lid.png")} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 kabayaki-text">
                                    <h3>{t("translation:japan.inKabayaki")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </div>
    );
}
export default ThaiMarket;

