import React from 'react';

import Burger from '../navbar/Burger';
import styled from 'styled-components';

import logo from '../../img/logo/logo-gpc.png';

const Nav = styled.nav` 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 1); 
  z-index: 999;

  @media (max-width: 768px) {
    padding: 0px 20px;
  }
`
const Logo = styled.img`
  max-width: 60px;
  height: auto; 
  padding: 5px;
`;

const Navbar = () => {
  return (
    <Nav>
      <Logo src={logo} />
      <Burger />
    </Nav>
  )
}
export default Navbar;

