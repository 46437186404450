// import React from "react";
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
 
import Backend from 'i18next-xhr-backend';
 
i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
 
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'th'],
 
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
 
    // saveMissing: true,
    // saveMissingTo: 'all',
 
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      // addPath: '/locales/add/{{lng}}/{{ns}}',
    },
  });
 
export default i18n;