import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";


// Translate
import { Translation, useTranslation } from 'react-i18next';

import bgBanner from '../../img/bg/344.jpg';

function TunaSoybeanChiliTH() {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);

    });

    return (
        <div>
            <section>
                <div className="banner-thai-market-tuna" style={{ backgroundImage: `url(${bgBanner})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12">
                                <h4>{t("tuna.steakinSoybeanChili")}</h4>
                                <p>{t("quote.q04")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-th-tuna-product">
                <div className="th-tuna-product">
                    <div className="container">
                        <div className="row justify-content-center align-items-center mt-5"> 
                            <div className="col-lg-6 col-md-6 col-11  text-center">
                                <div className="th-tuna-product-premium">
                                    <img src={require("../../img/logo/premium.svg")} />
                                    <img src={require("../../img/logo/omega3.svg")} className="pt-5" />
                                </div>
                                <div className="th-tuna-product-item">
                                    <img src={require("../../img/product/goldenprizeth-tuna-soybeanchili-lid.png")} />
                                    <p className="text-center">{t("netweight185")}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-5 col-11">
                                <div className="product-item-Ingredients">
                                    <ul>{t("ingredients.ingredients")}
                                        <li className="ml-5 mt-4">{t("ingredients.tunaChunk")} 70%</li>
                                        <li className="ml-5">{t("ingredients.soyaBeanOil")} 18%</li>
                                        <li className="ml-5">{t("ingredients.chili")}, {t("ingredients.water")}, {t("ingredients.salt")} 12%</li> 
                                    </ul>
                                </div>

                                <div className="product-item-info">
                                    <h6>{t("ingredients.informationFood")}</h6>
                                    <p>{t("ingredients.fish")}, {t("ingredients.soyaBean")}</p>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-80">
                            <div className="col-lg-5 col-md-6 col-10">
                                <div className="performance-facts">
                                    <div className="performance-facts__header">
                                        <h6 className="performance-facts__title">
                                            {t("nutrition.nutrition")}
                                        </h6>
                                        <p>{t("nutrition.servingSize")}: 1/2 can ( 55 {t("nutrition.draninedWeight")} )</p>
                                        <p>{t("nutrition.servingPer")}: 2</p>
                                    </div>

                                    <table className="performance-facts__table">
                                        <thead>
                                            <tr>
                                                <th colspan="3" className="small-info">
                                                    <h6><b>{t("nutrition.nutritionValue")}</b></h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="thick-row">
                                                <td colspan="3" className="small-info">
                                                    <b>{t("nutrition.dailyValue")}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">
                                                    <b>{t("nutrition.fat")} 22.1g</b>
                                                </th>
                                                <td>
                                                    <b>1%</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell">
                                                </td>
                                                <th>
                                                    {t("nutrition.saturatedFat")} 3.6g
                                                </th>
                                                <td>
                                                    <b>0%</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">
                                                    <b>{t("nutrition.cholesterol")} 30mg</b>
                                                </th>
                                                <td>
                                                    <b>10%</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th colspan="2">
                                                    <b>{t("nutrition.protein")} 22.2g</b>
                                                </th>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">
                                                    <b>{t("nutrition.carbohydrate")} 1g</b>
                                                </th>
                                                <td>
                                                    <b>0%</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell">
                                                </td>
                                                <th>
                                                    {t("nutrition.fiber")} 0g
                                                </th>
                                                <td>
                                                    0%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell">
                                                </td>
                                                <th>
                                                    {t("nutrition.sugar")} 0g
                                                </th>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr className="thin-end">
                                                <th colspan="2 ">
                                                    <b>{t("nutrition.sodium")} 0g</b>
                                                </th>
                                                <td>
                                                    <b>0%</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p className="text-center mt-3"><b>{t("nutrition.dailyValue")} *</b></p>

                                    <table className="performance-facts__table--grid">
                                        <tbody>
                                            <tr>
                                                <td colspan="2">
                                                    {t("nutrition.vitaminB2")}&nbsp;&nbsp;&nbsp;2%
                                                </td>
                                                <td>
                                                    {t("nutrition.iron")}&nbsp;&nbsp;&nbsp;4%
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    {/* <table className="performance-facts__table--small small-info">
                                        <thead>
                                            <tr>
                                                <td colspan="2"></td>
                                                <th>Calories:</th>
                                                <th>2,000</th>
                                                <th>2,500</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th colspan="2">Total Fat</th>
                                                <td>Less than</td>
                                                <td>65g</td>
                                                <td>80g</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th>Saturated Fat</th>
                                                <td>Less than</td>
                                                <td>20g</td>
                                                <td>25g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Cholesterol</th>
                                                <td>Less than</td>
                                                <td>300mg</td>
                                                <td>300 mg</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2">Sodium</th>
                                                <td>Less than</td>
                                                <td>2,400mg</td>
                                                <td>2,400mg</td>
                                            </tr>
                                            <tr>
                                                <th colspan="3">Total Carbohydrate</th>
                                                <td>300g</td>
                                                <td>375g</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th colspan="2">Dietary Fiber</th>
                                                <td>25g</td>
                                                <td>30g</td>
                                            </tr>
                                        </tbody>
                                    </table> */}

                                    {/* <p className="small-info">
                                        Calories per gram:
                                    </p>
                                    <p className="small-info text-center">
                                        Fat 9
                                        &bull;
                                        Carbohydrate 4
                                        &bull;
                                        Protein 4
                                    </p> */}
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-6 col-11">
                                <div className="energy-value">
                                    <h6>{t("nutrition.energy")} 245 Kcal ( {t("nutrition.energyFat")}  5 kcal ) </h6>
                                    <p>* {t("nutrition.recomment")}  </p>
                                </div>

                                <div className="tuna-product-list">
                                    <ul>
                                        <li>
                                            {t("nutrition.wildCaught")}
                                        </li>
                                        <li>
                                            {t("nutrition.noArtificial")}
                                        </li>
                                        <li>
                                            {t("nutrition.goodSource")}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tuna-food">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-11">
                            <div className="tuna-food-01 align-items-center text-black">
                                <img src={require("../../img/tuna/salad-vegetable.png")} />
                                <p>{t("food.menu1")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-11 text-right text-black">
                            <div className="tuna-food-02 align-items-center">
                                <img src={require("../../img/tuna/tuna-pizza.png")} /> 
                                <p>{t("food.menu2")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-11">
                            <div className="tuna-food-03 align-items-center text-black">
                                <img src={require("../../img/tuna/macaroni.png")} />
                                <p>{t("food.menu6")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
 
        </div>
    );
}


export default TunaSoybeanChiliTH;


