import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Translate
import { Translation, useTranslation } from 'react-i18next';


function InternationalMarket() {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div>
            <section className="our-story-our-products">
                <div class="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2> {t('products.internationalMarket')}</h2>
                                <p>{t('translation:ourProducts.paragraph01')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="our-tuna-inter">
                    <div className="container-fluid">
                        <div className="row justify-content-lg-end justify-content-center align-items-center">
                            <div className="col-lg-4 col-11">
                                <div className="tuna-inter-text">
                                    {/* <h3>{t('gpcb')}</h3> */}
                                    <h3>{t('tuna.tuna')}</h3>
                                    <div className="btn-our mt-5">
                                        <Link className="button-white" role="button" to="/tuna">
                                            {t("translation:view")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-11">
                                <div className="tuna-img">
                                    <img src={require("../img/tuna/1212123.jpg")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="our-sardine-inter">
                    <div className="container-fluid">
                        <div className="row justify-content-lg-start justify-content-center align-items-center">
                            <div className="col-lg-6 col-11">
                                <div className="sardine-img">
                                    <img src={require("../img/sardine/8855.jpg")} />
                                </div>
                            </div>
                            <div className="col-lg-5 col-11">
                                <div className="sardine-inter-text">
                                    <h3>{t('sardine.sardine')}</h3>

                                    <div className="btn-our mt-5">
                                        <Link className="button-white" role="button" to="/sardine">
                                            {t("translation:view")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="our-mackerel-inter">
                    <div className="container-fluid">
                        <div className="row justify-content-lg-end justify-content-center align-items-center">
                            <div className="col-lg-3 col-11">
                                <div className="mackerel-inter-text">
                                    <h3>{t('mackerel.mackerel')}</h3>

                                    <div className="btn-our mt-5">
                                        <Link className="button-white" role="button" to="/mackerel">
                                            {t("translation:view")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-11">
                                <div className="mackerel-img">
                                    <img src={require("../img/mackerel/1155.jpg")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default InternationalMarket;

