import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

// Translate
import { Translation, useTranslation } from 'react-i18next';

import bgBanner from '../../img/bg/5577881.jpg';

function MackerelinBrine() {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);

    });

    return (
        <div>
            <section>
                <div className="banner-inter-market" style={{ backgroundImage: `url(${bgBanner})`, backgroundPosition: `top right` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-12">
                                <h3>{t("mackerel.inBrine")}</h3> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-th-tuna-product mb-80">
                <div className="th-tuna-product">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-6 col-11 text-center">
                                <div className="th-tuna-product-premium">
                                    <img src={require("../../img/logo/omega3.svg")} className="pt-5" />
                                </div>
                                <div className="th-tuna-product-item">
                                    <img src={require("../../img/product/goldenprize-mackerel-brine.png")} />
                                    <p>{t("netweight200")}</p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5 col-11">
                                <div className="product-item-Ingredients">
                                    <ul>{t("ingredients.ingredients")}
                                        <li className="ml-5 mt-4">{t("mackerel.mackerel")}</li> 
                                        <li className="ml-5">{t("ingredients.water")}, {t("ingredients.salt")}</li>
                                    </ul>
                                </div>

                                <div className="product-item-info">
                                    <h6>{t("ingredients.informationFood")}</h6>
                                    <p>{t("ingredients.fish")}</p>
                                    <h6>{t("nutrition.textDes")}</h6>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-40 mb-80">
                            <div className="col-lg-6 col-md-6 col-10">
                                <div className="performance-facts">
                                    <div className="performance-facts__header">
                                        <h6 className="performance-facts__title">
                                            {t("nutrition.nutrition")}
                                        </h6>
                                        <p>{t("nutrition.servingPer")}: 3</p>
                                        <p>{t("nutrition.servingSize")}: 40g. (1/3 can) {t("nutrition.draninedWeight")}</p>
                                    </div>

                                    <table className="performance-facts__table--small small-info">
                                        <thead>
                                            <tr>
                                                <td colspan="2"></td>
                                                <th>Per serving(40g.)</th>
                                                <th className="pl-4">Per 100 g.</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.energykJ")}</b></th>
                                                <td className="text-right">157.2 kj</td>
                                                <td className="text-right">393 kj</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.energykCal")}</b></th>
                                                <td>37.6 kcal</td>
                                                <td>94 kcal</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.protein")}</b></th>
                                                <td>6.12 g</td>
                                                <td>15.3 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.carbo")}</b></th>
                                                <td>0 g</td>
                                                <td>0 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.fat")}</b></th>
                                                <td>1.41 g</td>
                                                <td>3.53 g</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.saturatedFat")}</th>
                                                <td>0.47 g</td>
                                                <td>1.18 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.sodium")}</b></th>
                                                <td>0.10 g</td>
                                                <td>0.24 g</td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.omega3")}</b></th>
                                                <td>0.22 g</td>
                                                <td>0.55 g</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className="bordet-top-table"><b>{t("nutrition.textDes")}</b></p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-6 col-11" >
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default MackerelinBrine;


