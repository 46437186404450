import React, { Component, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

// Translate
import { Translation, useTranslation } from 'react-i18next';

const Mackerel = () => {
    const { t, i18n } = useTranslation(["translation"]);


    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div>
            <section>
                <div className="mackerel-products">
                    <div className="container-fluid">
                        <div className="row text-center">
                            <div className="col-12"> 
                                <div className="inter-mackerel-text">
                                    <h2>{t('translation:gpcb')}</h2>
                                    <h1>{t('translation:mackerel.mackerel')}</h1>
                                </div> 
                            </div>
                            <div className="offset-lg-1 col-lg-2 col-md-12 col-12">
                                <div className="mackerel-product-item">
                                    <Link to="/mackerel_in_tomato_sauce">
                                        <img src={require("../../img/product/goldenprize-mackerel-tomato.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:mackerel.inTomato')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-12 col-12">
                                <div className="mackerel-product-item">
                                    <Link to="/mackerel_in_tomato_sauce_with_chili">
                                        <img src={require("../../img/product/goldenprize-mackerel-tomatoChili.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:mackerel.inTomatoChili')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-12 col-12">
                                <div className="mackerel-product-item">
                                    <Link to="/mackerel_in_brine">
                                        <img src={require("../../img/product/goldenprize-mackerel-brine.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:mackerel.inBrine')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-12 col-12">
                                <div className="mackerel-product-item">
                                    <Link to="/mackerel_in_natural_oil">
                                        <img src={require("../../img/product/goldenprize-mackerel-natural.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:mackerel.inNaturalOil')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-12 col-12">
                                <div className="mackerel-product-item">
                                    <Link to="/mackerel_in_natural_oil_with_chili">
                                        <img src={require("../../img/product/goldenprize-mackerel-naturalChili.png")} />
                                        <div class="overlay">
                                            <p><span>{t('translation:mackerel.inNaturalOilChili')}</span> <br />
                                                {t('translation:netweight200')}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div >
    );

}

export default Mackerel;