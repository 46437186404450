import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./../footer/footer.css";

// import your icons 
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import { ExternalLink } from 'react-external-link';

const Wave = (props) => (
  <svg
    class="waves"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
    shape-rendering="auto"
  >
    <defs>
      <path
        id="gentle-wave"
        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
      />
    </defs>
    <g class="parallax">
      <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(51, 101, 138, 0.7)" />
      <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(51, 101, 138, 0.5)" />
      <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(51, 101, 138, 0.3)" />
      <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(51, 101, 138, 1)" />
    </g>
  </svg>
);



const Footer = ({ open }) => {
  const { t, i18n } = useTranslation(["translation"]);

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  return (
    <div className="mt-5">
      <div className="footer-waves">
        <Wave />
      </div>

      <div className="content flex">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-3 col-12">
              <div className="footerGPC">
                <Link to="www">
                  <h6>{t("gpc")}</h6>
                </Link>
                <div className="mt-4">
                  {/* <a href="www.goldenprize.co.th">
                    <FontAwesomeIcon icon={faGlobe} className="mr-3 golden-prize-social" />
                  </a> */}

                  {/* <Link to="http://www.goldenprize.co.th/" target="_blank">
                    <FontAwesomeIcon icon={faGlobe}   className="mr-3 golden-prize-social" />
                  </Link>
                  <Link to="https://www.facebook.com/goldenprizethailand/" target="_blank">
                    <FontAwesomeIcon icon={faFacebook}  className="mr-3 golden-prize-social" />
                  </Link>
                  <Link to="https://www.instagram.com/goldenprizethailand/" target="_blank">
                    <FontAwesomeIcon icon={faInstagram}   className="golden-prize-social" />
                  </Link> */}

                  <ExternalLink href="http://www.goldenprize.co.th">
                    <FontAwesomeIcon icon={faGlobe} className="mt-3 mr-3 golden-prize-social" />
                  </ExternalLink>
                  <ExternalLink href="https://www.facebook.com/goldenprizethailand/">
                    <FontAwesomeIcon icon={faFacebook} className="mt-3 mr-3 golden-prize-social" />
                  </ExternalLink>
                  <ExternalLink href="https://www.instagram.com/goldenprizethailand/">
                    <FontAwesomeIcon icon={faInstagram} className="mt-3 mr-3 golden-prize-social" />
                  </ExternalLink>
 
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="footerNav">
                <Link to="/">
                  <p>{t("translation:home")}</p>
                </Link>
                <Link to="/our_story">
                  <p>{t("translation:our_story")}</p>
                </Link>
                <Link to="/thai_market">
                  <p>{t("translation:products.thaiMarket")}</p>
                </Link>
                <Link to="/">
                  <p>{t("translation:products.internationalMarket")}</p>
                </Link>
                <Link to="/sustainability">
                  <p>{t("translation:sustainability.sustainability")}</p>
                </Link>
                <Link to="/recipes">
                  <p>{t("recipes")}</p>
                </Link>
                <Link to="/contact-us">
                  <p>{t("contact")}</p>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="address-text">
                <p>
                  {t("translation:address.adrc01")}
                  <br />
                  {t("translation:address.adrc02")}
                  <br />
                  {t("translation:address.adrc03")}
                  <br />
                  {t("translation:address.adrc04")}
                  <br />
                  <br />
                  {t("translation:phone")}
                  <br />
                  {t("translation:email")}
                </p>
              </div>
            </div>
            <div class="col-lg-12 mt-4">
              <div class="copyright-text">
                <hr className="footer-line" />
                <p>
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script> All
                  rights reserved | Golden Prize Canning Co., Ltd.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
