import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

// Translate
import { Translation, useTranslation } from 'react-i18next';
 
import bgBanner from '../../img/bg/11221.jpg';
import bgMain from '../../img/bg/775566-3.jpg';

function SardineinTomatoTH() {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {
        window.scrollTo(0, 0);

    });

    return (
        <div>
            <section>
                <div className="banner-inter-market" style={{ backgroundImage: `url(${bgBanner})`, backgroundPosition: `bottom left` }}>
                    <div className="container">
                        <div className="row justify-content-end pt-top">
                            <div className="col-lg-6 col-12">
                                <h3>{t("sardine.inTomato")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-th-tuna-product mb-100">
                <div className="th-tuna-product">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-6 col-11 text-center">
                                <div className="th-tuna-product-premium">
                                    <img src={require("../../img/logo/omega3.svg")} className="pt-5" />
                                </div>
                                <div className="th-tuna-product-item">
                                    <img src={require("../../img/product/goldenprize-sardine-th.png")} />
                                    <p className="text-center">{t("netweight155")}</p>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5 col-11">
                                <div className="product-item-Ingredients">
                                    <ul>{t("ingredients.ingredients")}
                                        <li className="ml-5 mt-4">{t("sardine.sardine")} 60%</li>
                                        <li className="ml-5">{t("ingredients.tomato")} 40%</li>
                                        <li className="ml-5">{t("ingredients.monosodium")}</li>
                                        <li className="ml-5">{t("ingredients.stabilizing")}</li>
                                    </ul>
                                </div>

                                <div className="product-item-info">
                                    <h6>{t("ingredients.informationFood")}</h6>
                                    <p>{t("ingredients.fish")}</p>
                                    <h6>{t("nutrition.textDes")}</h6>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-40">
                            <div className="col-lg-6 col-md-6 col-11"> 
                                <div className="performance-facts">
                                    <div className="performance-facts__header">
                                        <h6 className="performance-facts__title text-center">
                                            {t("nutrition.nutrition")}
                                        </h6>
                                        <p>{t("nutrition.servingPer")}: 2</p>
                                        <p>{t("nutrition.servingSize")}: 1/2 can (77 g)</p>
                                    </div>

                                    <div className="performance-facts__header">
                                        <p><b>{t("nutrition.nutritionValue")}</b></p>
                                        <p><b>{t("nutrition.energy")}</b> 120 Kcal ( {t("nutrition.energyFat")} 70 kcal ) </p>
                                    </div>

                                    <table className="performance-facts__table--small small-info">
                                        <thead>
                                            <tr>
                                                <td colspan="2"></td>
                                                <th className="text-right"><b>{t("nutrition.dailyValue")}</b></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.fat")} 8 g</b></th>
                                                <td>12%</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.saturatedFat")} 3 g</th>
                                                <td>15%</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.fat01")} 1 g</th>
                                                <td> </td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.fat02")} 4 g</th>
                                                <td> </td>
                                            </tr>

                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.cholesterol")} 40 mg</b></th>
                                                <td>13%</td>
                                            </tr>

                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.protein")} 10 g</b></th>
                                            </tr>

                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.carbohydrate02")} 3 g</b></th>
                                                <td>1%</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.fiber")} less than 1 g</th>
                                                <td>3%</td>
                                            </tr>
                                            <tr>
                                                <td className="blank-cell"></td>
                                                <th> {t("nutrition.sugar")} 0 g</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th colspan="2"><b>{t("nutrition.sodium")} 260 mg</b></th>
                                                <td>13%</td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-6 col-11" >
                                <div className="performance-facts">
                                    <table className="table-percent">
                                        <tbody>
                                            <tr>
                                                <th colspan="2">
                                                    <b>{t("nutrition.percentage")} (Percent Thai RDI)</b>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td> {t("nutrition.vitaminA")}&nbsp;&nbsp;0%  </td>
                                                <td> {t("nutrition.vitaminB1")}&nbsp;&nbsp;0% </td>
                                            </tr>
                                            <tr>
                                                <td> {t("nutrition.vitaminB2")}&nbsp;&nbsp;2% </td>
                                                <td> {t("nutrition.calcium")}&nbsp;&nbsp;15% </td>
                                            </tr>
                                            <tr> <td> {t("nutrition.iron")}&nbsp;&nbsp;&nbsp;4% </td>
                                            </tr>

                                            <tr className="percent">
                                                <th colspan="2"><b>* {t("nutrition.recomment")}</b></th>
                                            </tr>

                                            <tr className="percent">
                                                <th colspan="2">{t("nutrition.text")}</th>
                                            </tr>
                                            <tr>
                                                <th>{t("nutrition.fat")}&nbsp;&nbsp;less than</th>
                                                <td>65 g</td>
                                            </tr>
                                            <tr>
                                                <th>&nbsp;&nbsp;{t("nutrition.saturatedFat")}&nbsp;&nbsp;less than  </th>
                                                <td>20 g</td>
                                            </tr>
                                            <tr>
                                                <th>{t("nutrition.cholesterol")}&nbsp;&nbsp;less than</th>
                                                <td>300 mg</td>
                                            </tr>
                                            <tr>
                                                <th>{t("nutrition.carbohydrate02")}</th>
                                                <td>300 g</td>
                                            </tr>
                                            <tr>
                                                <th>&nbsp;&nbsp;{t("nutrition.fiber")}</th>
                                                <td>25 g</td>
                                            </tr>
                                            <tr>
                                                <th>{t("nutrition.sodium")}</th>
                                                <td>2,000 mg</td>
                                            </tr>
                                            <tr className="percent">
                                                <th colspan="2">{t("nutrition.energykCal")}/g : {t("nutrition.fatt")} = 9; {t("nutrition.protein")} = 4; {t("nutrition.carbo")} = 4
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="gallery">
                            <img src={require("../../img/sardine/112233.jpg")} />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="gallery">
                            <img src={require("../../img/sardine/112233.jpg")} />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="gallery">
                            <img src={require("../../img/sardine/112233.jpg")} />
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    );
}

export default SardineinTomatoTH;


