import React from 'react';
import './App.css';

// Router 
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

// import file 
import Index from './components/Index';
import ThaiMarket from './components/Thai_Market';
import InternationalMarket from './components/International_market';
import Tuna from './components/tuna/tuna';
import Sardine from './components/sardine/Sardine';
import Mackerel from './components/mackerel/Mackerel';
import OurStory from './components/Our_story';
import Sustainability from './components/Sustainability';
import Recipes from './components/Recipes';
import Contact from './components/contact';

import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/footer';

import TunaSoybeanTH from './components/tuna/TunaSoybeanTH';
import TunaSoybeanChiliTH from './components/tuna/TunaSoybeanChiliTH';
import TunaSpringWaterTH from './components/tuna/TunaSpringWaterTH';

import SardineinTomatoTH from './components/sardine/SardineinTomatoTH';
import MackerelinTomatoSauceTH from './components/mackerel/MackerelinTomatoSauceTH';

import TunaChunkinSoyabean from './components/tuna/TunaChunkinSoyabean';
import TunaChunkinBrine from './components/tuna/TunaChunkinBrine';
import TunaChunkinSpringWater from './components/tuna/TunaChunkinSpringWater';
import TunaChunkinOliveOil from './components/tuna/TunaChunkinOliveOil';
import TunaChunkinTomatoSauce from './components/tuna/TunaChunkinToamatoSauce';
import TunaFlakesinBrine from './components/tuna/TunaFlakesinBrine';
import TunaFlakesinOilwithChili from './components/tuna/TunaFlakesinOilwithChili';
import TunaFlakesinSoyabeanOil from './components/tuna/TunaFlakesinSoyabeanOil';
import TunaSaladwithVegetablemexican from './components/tuna/TunaSaladwithVegetablemexican';
import TunaSpreadinMayonnaise from './components/tuna/TunaSpreadinMayonnaise';

import SardineinBrine from './components/sardine/SardineinBrine';
import SardineinTomato from './components/sardine/SardineinTomato';
import SardineinNaturalOil from './components/sardine/SardineinNaturalOil';
import SardineinNaturalOilwithChili from './components/sardine/SardineinNaturalOilwithChili';
import SardineinSoysauce from './components/sardine/SardineinSoysauce';

import MackerelinTomatoSauce from './components/mackerel/MackerelinTomatoSauce';
import MackerelinTomatoSaucewithChili from './components/mackerel/MackerelinTomatoSaucewithChili';
import MackerelinBrine from './components/mackerel/MackerelinBrine';
import MackerelinNaturalOil from './components/mackerel/MackerelinNaturalOil';
import MackerelinNaturalOilwithChili from './components/mackerel/MackerelinNaturalOilwithChili';


// Translate
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t, i18n } = useTranslation(["translation"]);

  return (
    <div className="App">
      <Router>
        <Switch>
          {/* <Route path="/" exact component={Home} /> */}
          <Route path="/" exact component={Index} />
          <Route path="/our_story" component={OurStory} />
          <Route path="/sustainability" component={Sustainability} />
          <Route path="/thai_market" component={ThaiMarket} />
          <Route path="/international_market" component={InternationalMarket} />
          <Route path="/tuna" component={Tuna} />
          <Route path="/sardine" component={Sardine} />
          <Route path="/mackerel" component={Mackerel} />
          <Route path="/recipes" component={Recipes} />
          <Route path="/contact-us" component={Contact} />

          <Route path="/tuna_in_soybean_th" component={TunaSoybeanTH} />
          <Route path="/tuna_in_soybean_chili_th" component={TunaSoybeanChiliTH} />
          <Route path="/tuna_in_spring_water_th" component={TunaSpringWaterTH} />

         <Route path="/sardine_in_tomato_sauce_th" component={SardineinTomatoTH} />
           <Route path="/mackerel_in_tomato_sauce_th" component={MackerelinTomatoSauceTH} />

          <Route path="/tuna_chunk_in_soya_bean_oil" component={TunaChunkinSoyabean} />
          <Route path="/tuna_chunk_in_brine" component={TunaChunkinBrine} />
          <Route path="/tuna_chunk_in_spring_water" component={TunaChunkinSpringWater} />
          <Route path="/tuna_chunk_in_olive_oil" component={TunaChunkinOliveOil} />
          <Route path="/tuna_chunk_in_tomato_sauce" component={TunaChunkinTomatoSauce} />
          <Route path="/tuna_flakes_in_brine" component={TunaFlakesinBrine} />
          <Route path="/tuna_flakes_in_oil_with_red_chili" component={TunaFlakesinOilwithChili} />
          <Route path="/tuna_flakes_in_soya_bean_oil" component={TunaFlakesinSoyabeanOil} />
          <Route path="/tuna_salad_with_vegetables_maxican_style" component={TunaSaladwithVegetablemexican} />
          <Route path="/tuna_spread_in_mayonnaise" component={TunaSpreadinMayonnaise} />

          <Route path="/sardine_in_brine" component={SardineinBrine} />
          <Route path="/sardine_in_tomato" component={SardineinTomato} />
          <Route path="/sardine_in_natural_oil" component={SardineinNaturalOil} />
          <Route path="/sardine_in_natural_oil_with_chili" component={SardineinNaturalOilwithChili} />
          <Route path="/sardine_in_soy_sauce" component={SardineinSoysauce} />

          <Route path="/mackerel_in_tomato_sauce" component={MackerelinTomatoSauce} />
          <Route path="/mackerel_in_brine" component={MackerelinBrine} />
          <Route path="/mackerel_in_tomato_sauce_with_chili" component={MackerelinTomatoSaucewithChili} />
          <Route path="/mackerel_in_natural_oil" component={MackerelinNaturalOil} />
          <Route path="/mackerel_in_natural_oil_with_chili" component={MackerelinNaturalOilwithChili} />
        </Switch>

        <Navbar />

        <Footer />
      </Router>
    </div>
  );
}

const Home = () => (
  <div>
    <Index />
  </div>
);



export default App;
