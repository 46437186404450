import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Translate
import { Translation, useTranslation } from 'react-i18next';




function Recipes() {
    const { t, i18n } = useTranslation(["translation"]);

    useEffect(() => {  
        window.scrollTo(0, 0);
   
    });
    
    return (
        <div className="golden-prize-recipes">
            <section>
                <div className="banner-recipes">
                    <div className="text-title">
                        <h1>{t("translation:recipes")}</h1>
                    </div>

                </div>
            </section>

            <section>
                <div className="recipes mt-60">
                    <div className="container-fluid"> 
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="golden-prize-youtube">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/ZDzNlfiYht8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="golden-prize-youtube">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/i9_sn5GiMNA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="golden-prize-youtube">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/U59_WrrrM-U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="golden-prize-youtube">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/0f73RAtJNbc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="golden-prize-youtube">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/9G2xIHWE6hk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="golden-prize-youtube">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/o_UY9bj3Zyo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="golden-prize-youtube">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/lzMNAdjteF0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
export default Recipes;

