import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// jQuery
import $ from "jquery";

// Translate
import { useTranslation } from "react-i18next";

import Banner from "./navbar/Banner";


// images
import bgJapanMiso from "./../img/japanese/bg-japan-miso.png";
import bgJapanSoysauce from "./../img/japanese/bg-japan-soysauce.png";
import bgJapanKabayaki from "./../img/japanese/bg-japan-kabayaki.png";

// GSAP ScrollTrigger
import { gsap, TimelineLite, TweenMax, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);



function Index(props) {
    const { t, i18n } = useTranslation(["translation"]);

    // https://blog.logrocket.com/how-to-use-the-gsap-scrolltrigger-plugin-in-react/

    let app = useRef(null);
    let storycontent = useRef(null);

    let tl = new TimelineLite({ delay: 0.3 });
    const [HeightP, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        window.scrollTo(0, 0);


        //Remove initial flash
        TweenMax.to(app, 0, { css: { visibility: "visible" } });

        gsap.from(".story-content-line", {
            delay: "0.2",
            y: "40",
            opacity: 0,
            ease: "Power3.easeOut",
            scrollTrigger: {
                trigger: ".story-content-line",
                start: "top 70%",
                end: "bottom 40%",
                // markers: true,
                toggleActions: "restart none none none",
            },
        });
        gsap.from(".paragraphFirst", {
            delay: "0.4",
            y: "30",
            opacity: 0,
            ease: "ease-in",
            scrollTrigger: {
                trigger: ".paragraphFirst",
                // markers: false,
                start: "top 70%",
                end: "bottom 40%",
                toggleActions: "play none none none",
            },
        });
        gsap.from(".paragraphSecond", {
            delay: "0.6",
            y: "30",
            opacity: 0,
            ease: "ease-in",
            scrollTrigger: {
                trigger: ".paragraphSecond",
                // markers: false,
                start: "top 70%",
                end: "bottom 40%",
                toggleActions: "play none none none",
            },
        });

        // // Section Our Products
        gsap.from(".our-products-content-line", {
            delay: "0.2",
            y: "30",
            opacity: 0,
            ease: "ease-in",
            scrollTrigger: {
                trigger: ".our-products-content-line",
                // markers: false,
                start: "top 80%",
                end: "bottom 40%",
                toggleActions: "restart none none none",
            },
        });
        gsap.from(".paragraphProducts", {
            delay: "0.4",
            y: "20",
            opacity: 0,
            ease: "ease-in",
            scrollTrigger: {
                trigger: ".paragraphProducts",
                // markers: false,
                start: "top 80%",
                end: "bottom 40%",
                toggleActions: "play none none none",
            },
        });
 

        // // TIMELESS TUNA
        gsap.from(".can-top-view", {
            delay: "0.6",
            duration: 2,
            opacity: 1,
            rotate: -360, // x:"-50vw",
            scrollTrigger: {
                trigger: ".can-top-view",
                markers: false,
                start: "top 60%",
                end: "bottom 10%",
                toggleActions: "restart complete reverse none",
            },
        });
   
    
    });

    return (
        <div ref={(el) => (app = el)}>
            <Banner />

            <section className="golden-prize-our-story">
                <div className="container">
                    <div className="row justify-content-center text-left">
                        <div className="col-lg-10 col-12">
                            <div className="story-content">
                                <div className="story-content-title text-gold">
                                    <img src={require("./../img/01.png")} />
                                    <h2>
                                        <div className="story-content-line">
                                            <span>{t("translation:our")}</span>
                                        </div>
                                        <div className="story-content-line">
                                            {t("translation:ourStory.ourStory")}
                                        </div>
                                    </h2>
                                </div>
                                <p className="mt-5 paragraphFirst text-white">
                                    {t("translation:ourStory.paragraph01")}
                                </p>
                                <p className="mt-4 paragraphSecond text-white">
                                    {t("translation:ourStory.paragraph02")}
                                </p>
                            </div>
                            <div className="text-center btn-our mt-60">
                                <Link className="button-white" role="button" to="/our_story">
                                    {t("translation:view")}
                                </Link>
                            </div>
                            <div className="text-center story-image">
                                <img src={require("./../img/opened-cans-top-view.png")} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-our-products">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="our-products-content">
                                <div className="our-products-content-title text-gold">
                                    <h2>
                                        <div className="our-products-content-line">
                                            <span>{t("translation:our")}</span>
                                        </div>
                                        <div className="our-products-content-line">
                                            {t("translation:ourProducts.ourProducts")}
                                        </div>
                                    </h2>
                                    <img src={require("./../img/01.png")} />
                                </div>

                                <p className="mt-5 paragraphProducts text-white">
                                    {t("translation:ourProducts.paragraph01")}
                                </p>
                                <div className="text-center btn-our text-white">
                                    <Link className="button-white" role="button" to="/international_market">
                                        {t("translation:view")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-timeless-tuna">
                <div className="timeless-tuna">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="can-top-view">
                                <img src={require("./../img/tuna-lid.png")} />
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 col-11">
                                <div className="timeless-tuna-content">
                                    <div className="timeless-tuna-title">
                                        <h2>{t("translation:timelessTuna.timelessTuna")}</h2>
                                    </div>
                                    <div className="timeless-tuna-line mt-5">
                                        <p>
                                            <span>{t("translation:gpcb")}</span>{" "}
                                            {t("translation:timelessTuna.text")}
                                        </p>
                                    </div>
                                    <div className="mt-5 btn-products">
                                        <Link className="button" role="button" to="/thai_market">
                                            {t("translation:view")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="timeless-tuna-products">
                            <div className="row justify-content-center">
                                <div className="col-lg-3 col-md-4 col-12">
                                    <div className="tuna-item">
                                        <Link to="/tuna_in_soybean_th">
                                            <img src={require("./../img/product/goldenprizeth-tuna-soybean-lid.png")} />
                                            <p>{t("translation:tuna.steakinSoybean")}</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-12">
                                    <div className="tuna-item">
                                        <Link to="/tuna_in_soybean_chili_th">
                                            <img src={require("./../img/product/goldenprizeth-tuna-soybeanchili-lid.png")} />
                                            <p>{t("translation:tuna.steakinSoybeanChili")}</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-12">
                                    <div className="tuna-item">
                                        <Link to="">
                                            <img src={require("./../img/product/goldenprizeth-tuna-springwater-lid.png")} />
                                            <p>{t("translation:tuna.steakinSpringWater")}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-shining-star-sardine">
                <div className="container-fluid">
                    <div className="shining-star-sardine-content">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="sardine-img">
                                    <img src={require("./../img/33.jpg")} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-11">
                                <div className="sardine-title text-gold">
                                    <h3>{t("translation:sardine.quote")}</h3>
                                    <h2>{t("translation:sardine.sardine")}</h2>
                                </div>
                                <div className="sardine-line text-white">
                                    <p className="mt-5">
                                        <span>{t("translation:gpcb")}</span>{" "}
                                        {t("translation:sardine.text")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-mouth-watering-mackerel">
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-6 col-11">
                            <div className="mouth-watering-mackerel-content">
                                <div className="mackerel-title">
                                    <h3>{t("translation:mackerel.quote")}</h3>
                                    <h2>{t("translation:mackerel.mackerel")}</h2>
                                </div>
                                <div className="mackerel-line">
                                    <p className="mt-5">
                                        <span>{t("translation:gpcb")}</span>{" "}
                                        {t("translation:mackerel.text")}{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-right">
                            <div className="mouth-watering-mackerel-img">
                                <img src={require("./../img/222.jpg")} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-japanese">
                <div className="a-taste-of-japanese-delights-banner">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-11">
                                <div className="a-taste-of-japanese-delights text-white">
                                    <h2>{t('translation:japan.quote')}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="offset-lg-1 offset-md-1 col-lg-7 col-10">
                                <div className="a-taste-of-japanese-delights-title">
                                    <h3>{t('translation:japan.text01')}</h3>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="a-taste-of-japanese-delights-text">
                                            <p className="box-1"><span>{t('translation:japan.text02')}</span> {t('translation:japan.text03')}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 mt-4">
                                        <div className="a-taste-of-japanese-delights-text">
                                            <p className="box-2">{t('translation:japan.text04')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sardines-japanese">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sardines-japanese-title">
                                    <h2>{t("translation:japan.sardinesJapan")}</h2>
                                </div>
                                <div className="sardines-japanese-text">
                                    <p className="pt-5">
                                        {t("translation:japan.sardinesJapanIntro")}
                                    </p>
                                    <p className="pt-3">
                                        {t("translation:japan.ourSardinesJapan")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sardines-japanese-products">
                    <div className="sardines-japanese-products-items" style={{ backgroundImage: `url(${bgJapanSoysauce})` }}>
                        <div className="container">
                            <div className="row justify-content-lg-center align-items-center">
                                <div className="col-lg-4 col-12 soysauce-text">
                                    <p className="mt-3">{t("translation:japan.inSoySauceText")}</p>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="soysauce-img text-center">
                                        <img src={require("./../img/japanese/sardines-soy-sauce-lid.png")} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 soysauce-text">
                                    <h3>{t("translation:japan.inSoySauce")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sardines-japanese-products-items" style={{ backgroundImage: `url(${bgJapanMiso})` }}>
                        <div className="container">
                            <div className="row justify-content-lg-center align-items-center">
                                <div className="col-lg-4 col-12 miso-text">
                                    <p className="mt-3">{t("translation:japan.inMisoText")}</p>
                                </div>
                                <div className="col-lg-4 col-12 text-center">
                                    <div className="miso-img">
                                        <img src={require("./../img/japanese/sardines-miso-lid.png")} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 miso-text">
                                    <h3>{t("translation:japan.inMiso")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sardines-japanese-products-items" style={{ backgroundImage: `url(${bgJapanKabayaki})` }}>
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-4 col-12 kabayaki-text">
                                    <p className="mt-3">{t("translation:japan.inKabayakiText")}</p>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="kabayaki-img text-center">
                                        <img src={require("./../img/japanese/sardines-kabayaki-lid.png")} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-10 kabayaki-text">
                                    <h3>{t("translation:japan.inKabayaki")}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="golden-prize-where-to-find-us">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-lg-6 col-12">
                            <div className="where-to-find-us">
                                <div className="where-to-find-us-text">
                                    <h2>{t("translation:findUs.findUs")}</h2>
                                </div>
                                <div className=" text-center">
                                    <div className="online">
                                        <h6>{t("translation:findUs.online")}</h6>
                                        <a href="https://www.lazada.co.th/shop/golden-prize"> 
                                            <img src={require("./../img/logo/lazada-logo.png")} />
                                        </a> 
                                        <a href="https://shopee.co.th/goldenprize_thailand"> 
                                            <img src={require("./../img/logo/shopee-logo.png")} />
                                        </a> 
                                         {/*  <Link to="https://www.lazada.co.th/shop/golden-prize">
                                            <img src={require("./../img/logo/lazada-logo.png")} />
                                        </Link>
                                        <Link to="https://shopee.co.th/goldenprize_thailand">
                                            <img src={require("./../img/logo/shopee-logo.png")} />
                                        </Link> */} 
                                    </div>
                                </div>
                                <div className="  text-center">
                                    <div className="japan-stores">
                                        <h6>{t("translation:findUs.japanStores")}</h6>
                                        <img src={require("./../img/logo/dondonki-logo.png")} />
                                        <img src={require("./../img/logo/fuji-logo.png")} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-12 text-center mt-5">
                            {/* <div id="fb-root"></div> */}
                            <div className="fb-page" data-href="https://www.facebook.com/goldenprizethailand/" data-tabs="timeline" data-width="420" data-height="540" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                                <blockquote cite="https://www.facebook.com/goldenprizethailand/" class="fb-xfbml-parse-ignore">
                                    {/* <a href="https://www.facebook.com/goldenprizethailand/">Golden Prize Thailand</a> */}
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Index;


